import { FC, useContext, useState } from 'react';
import {
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  EmptyState,
  GroupsIcon,
  SearchInput,
} from '@bp/ui-components';
import styles from './ProfileSelection.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ProfileSelectionElement } from './ProfileSelectionElement';
import { ProfileSelectionListHead } from './ProfileSelectionListHead';
import { DesktopContext } from '../../context/IsDesktop';
import { SortDirection } from '@bp/bp-graphql-types';

export type SelectorType = {
  value: string;
  label: string;
};

type ProfileSelectionProps = {
  items: SelectorType[];
  selectedItems: SelectorType[];
  onSelect: (items: SelectorType[]) => void;
  onRemove: (items: SelectorType[]) => void;
};

export const ProfileSelection: FC<ProfileSelectionProps> = ({ items, selectedItems, onSelect, onRemove }) => {
  const { t } = useTranslation();
  const { isDesktop } = useContext(DesktopContext);

  const [markedItems, setMarkedItems] = useState<string[]>([]);
  const [markedSelectedItems, setMarkedSelectedItems] = useState<string[]>([]);

  const [itemsSortDirection, setItemsSortDirection] = useState<SortDirection>(SortDirection.Asc);
  const [selectedItemsSortDirection, setSeletedItemsSortDirection] = useState<SortDirection>(SortDirection.Asc);

  // Search
  const [selectableSearchValue, setSelectableSearchValue] = useState('');
  const [selectedSearchValue, setSelectedSearchValue] = useState('');

  // listen ein / ausklappen in mobile
  const [topSelectorOpen, setTopSelectorOpen] = useState(true);
  const [btmSelectorOpen, setBtmSelectorOpen] = useState(true);

  const selectable = (
    selectableSearchValue ? items.filter((item) => item.label.includes(selectableSearchValue)) : items
  ).sort((a, b) => {
    if (itemsSortDirection === SortDirection.Asc) {
      return a.label && b.label ? (a.label > b.label ? 1 : -1) : -1;
    } else {
      return a.label && b.label ? (a.label < b.label ? 1 : -1) : -1;
    }
  });

  const selected = (
    selectedSearchValue ? selectedItems.filter((item) => item.label.includes(selectedSearchValue)) : selectedItems
  ).sort((a, b) => {
    if (selectedItemsSortDirection === SortDirection.Asc) {
      return a.label && b.label ? (a.label > b.label ? 1 : -1) : -1;
    } else {
      return a.label && b.label ? (a.label < b.label ? 1 : -1) : -1;
    }
  });

  const reset = () => {
    setMarkedItems([]);
    setMarkedSelectedItems([]);
  };

  return (
    <div className={styles.selector}>
      {/* SELECTABLE */}
      <div className={styles.select}>
        <div className={styles.header}>
          <div className={styles.headline}>{`${t('profiles.profilesForSelection')} (${markedItems.length}/${
            selectable.length
          })`}</div>

          {!isDesktop && (
            <ArrowDownIcon
              onClick={() => setTopSelectorOpen((prev) => !prev)}
              className={classNames([
                'svg-icon ',
                {
                  [styles.rotate180]: topSelectorOpen,
                },
              ])}
            />
          )}
        </div>

        {topSelectorOpen && (
          <>
            <div className={styles.actions}>
              <SearchInput
                className='my-4'
                name='profile'
                onChange={(e) => setSelectableSearchValue(e.target.value)}
                value={selectableSearchValue}
                placeholder={t('common.search')}
              />
              <Button className='ml-auto' hierarchy='secondary' onClick={() => setMarkedItems([])}>
                {t('common.clearSelection')}
              </Button>
            </div>
            <ProfileSelectionListHead
              onChange={(event) => {
                if (event.target.checked) {
                  setMarkedItems(selectable.map((item) => item.value));
                } else {
                  setMarkedItems([]);
                }
              }}
              sortDir={itemsSortDirection}
              sortDirChange={(sortDirection) => {
                setItemsSortDirection(sortDirection);
              }}
            />
            {selectable.length === 0 ? (
              <EmptyState
                size='small'
                fitParent
                icon={<GroupsIcon className='svg-icon' />}
                iconColor='var(--color-primary-light)'
                title={t('common.nothingFound')}
              />
            ) : (
              <div className={classNames(styles.scroll, 'has-scrollbar')}>
                {selectable.length > 0 && (
                  <>
                    {selectable.map((p) => {
                      return (
                        <ProfileSelectionElement
                          key={p.value}
                          item={p}
                          onClick={(item) => {
                            if (markedItems.includes(item.value)) {
                              setMarkedItems([
                                ...markedItems.filter((m) => {
                                  return item.value !== m;
                                }),
                              ]);
                            } else {
                              setMarkedItems([...markedItems, item.value]);
                            }
                          }}
                          onDoubleClick={(item) => {
                            setMarkedItems([
                              ...markedItems.filter((m) => {
                                return item.value !== m;
                              }),
                            ]);
                            onSelect([item]);
                          }}
                          isSelected={markedItems.includes(p.value)}
                        />
                      );
                    })}
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>

      {/* BUTTONS */}
      <div className={styles.buttons}>
        <Button
          className='mb-2'
          hierarchy='secondary'
          icon={<ArrowLeftIcon className={classNames(['svg-icon large', { [styles.rotate90]: !isDesktop }])} />}
          onClick={() => {
            onRemove(selected.filter((i) => markedSelectedItems.includes(i.value)));
            reset();
          }}
        />
        <Button
          className='mt-2'
          hierarchy='secondary'
          icon={
            <ArrowRightIcon
              className={classNames([
                'svg-icon large',
                {
                  [styles.rotate90]: !isDesktop,
                },
              ])}
            />
          }
          onClick={() => {
            onSelect(selectable.filter((i) => markedItems.includes(i.value)));
            reset();
          }}
        />
      </div>

      {/* SELECTED */}
      <div className={classNames(styles.select, styles.right)}>
        <div className={styles.header}>
          <div className={styles.headline}>{`${t('common.selected')} (${markedSelectedItems.length}/${
            selected.length
          })`}</div>

          {!isDesktop && (
            <ArrowDownIcon
              onClick={() => setBtmSelectorOpen((prev) => !prev)}
              className={classNames([
                'svg-icon',
                {
                  [styles.rotate180]: btmSelectorOpen,
                },
              ])}
            />
          )}
        </div>

        {btmSelectorOpen && (
          <>
            <div className={styles.actions}>
              <SearchInput
                className='my-4'
                name='selected-search'
                onChange={(e) => setSelectedSearchValue(e.target.value)}
                value={selectedSearchValue}
                placeholder={t('common.search')}
              />
              <Button className='ml-auto' hierarchy='secondary' onClick={() => setMarkedSelectedItems([])}>
                {t('common.clearSelection')}
              </Button>
            </div>
            <ProfileSelectionListHead
              onChange={(event) => {
                if (event.target.checked) {
                  setMarkedSelectedItems(selected.map((item) => item.value));
                } else {
                  setMarkedSelectedItems([]);
                }
              }}
              sortDir={selectedItemsSortDirection}
              sortDirChange={(sortDirection) => {
                setSeletedItemsSortDirection(sortDirection);
              }}
            />
            {selected.length === 0 ? (
              <EmptyState
                size='small'
                fitParent
                icon={<GroupsIcon className='svg-icon' />}
                iconColor='var(--color-primary-light)'
                title={t('common.nothingSelected')}
              />
            ) : (
              <div className={classNames(styles.scroll, 'has-scrollbar')}>
                {selected.length > 0 && (
                  <>
                    {selected.map((item) => {
                      return (
                        <ProfileSelectionElement
                          key={item.value}
                          item={item}
                          onClick={(item) => {
                            if (markedSelectedItems.includes(item.value)) {
                              setMarkedSelectedItems([
                                ...markedSelectedItems.filter((m) => {
                                  return item.value !== m;
                                }),
                              ]);
                            } else {
                              setMarkedSelectedItems([...markedSelectedItems, item.value]);
                            }
                          }}
                          onDoubleClick={(item) => {
                            setMarkedSelectedItems([
                              ...markedSelectedItems.filter((m) => {
                                return item.value !== m;
                              }),
                            ]);
                            onRemove([item]);
                          }}
                          isSelected={markedSelectedItems.includes(item.value)}
                        />
                      );
                    })}
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
