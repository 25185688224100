import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AssignmentTab.module.scss';
import { Checkbox, Grid, GridColumn, GridRow } from '@bp/ui-components';
import { niceDate } from '../../utils/dateCalculations';
import { useBpSetAllowSubmissionsAfterDueDateMutation } from '../../client/bp-graphql-client-defs';
import { BpCard } from '../BpCard/BpCard';
import { FileTable } from '../FileTable/FileTable';
import classNames from 'classnames';
import { BpTipTapText } from '../BpTipTapText/BpTipTapText';
import { Assignment } from '@bp/bp-graphql-types';

export type AssignmentTabProps = { data: Assignment | undefined };

export const AssignmentTab: FC<AssignmentTabProps> = ({ data }: AssignmentTabProps) => {
  const { t } = useTranslation();

  const [, updateAssignment] = useBpSetAllowSubmissionsAfterDueDateMutation();
  const material = data?.material;

  const [allowAfterDue, setAllowAfterDue] = useState(data?.allowSubmissionAfterDueDate ?? false);

  return (
    <div className={styles['assignment-tab']}>
      <Grid>
        <GridRow>
          <GridColumn width={8}>
            <BpCard className={styles.data} noPadding header={{ headline: t('assignments.task') }}>
              {material?.text && (
                <BpTipTapText customPadding='0 var(--spacing-6)' className='mb-4' content={material?.text ?? ''} />
              )}
              <FileTable className={styles.list} mode={'show'} files={material?.fileEntries ?? []} />
            </BpCard>
          </GridColumn>
          <GridColumn width={4}>
            <BpCard className={styles.due} header={{ headline: t('common.due_noun') }}>
              <div className={styles.title}>{t('common.visibleFrom')}</div>
              <div className={styles.date}>{data?.visibleFrom ? niceDate(data.visibleFrom, 'medium') : '-'}</div>
              <div className={classNames(styles.title, styles.second)}>{t('common.dueDate')}</div>
              <div className={styles.date}>{data?.dueDate ? niceDate(data.dueDate, 'medium') : '-'}</div>
              <Checkbox
                className={styles.checkbox}
                label={t('submissions.allowAfterDue')}
                name='allowAfterDue'
                checked={allowAfterDue ?? false}
                onChange={(e) => {
                  updateAssignment({
                    update: { allowSubmissionAfterDueDate: e.target.checked },
                  }).then((resp) => {
                    setAllowAfterDue(resp.data?.updateAssignments.assignments[0].allowSubmissionAfterDueDate ?? false);
                  });
                }}
              />
            </BpCard>
          </GridColumn>
        </GridRow>
      </Grid>
    </div>
  );
};
