import { Input, Select, Switch } from '@bp/ui-components';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { AppointmentFormValues } from '../../types';
import { useCreateSelectOptions } from '../../../../hooks/useCreateSelectOptions';
import { OrganizationConfigContext } from '../../../../context/OrganizationConfigContext';

export const VirtualMeetingSettings = () => {
  const { t } = useTranslation();
  const { zoomLicense, bigBlueButtonLicense, allowRecordings } = useContext(OrganizationConfigContext);

  const { values, initialValues, errors, handleBlur, setFieldValue, handleChange } =
    useFormikContext<AppointmentFormValues>();

  const availableServices: { value: string; label: string }[] = [];
  if (zoomLicense) {
    availableServices.push({ value: 'zoom', label: 'Zoom' });
  }
  if (bigBlueButtonLicense) {
    availableServices.push({ value: 'bbb', label: 'Big Blue Button' });
  }

  const serviceOpts = useCreateSelectOptions(availableServices, 'value', 'label');

  return (
    // TODO style with inline classes
    <div>
      <Select
        name='service'
        options={serviceOpts}
        placeholder={t('meetings.chooseService.placeholder')}
        label={t('meetings.chooseService.label')}
        onChange={(value) => setFieldValue('service', value)}
        error={errors.service?.label}
        onBlur={handleBlur}
        defaultValue={serviceOpts.find((opt) =>
          initialValues.categories
            ? initialValues?.categories.includes(opt.value as string) || availableServices.length === 1
            : availableServices.length === 1,
        )}
      />
      <div>
        <Input
          type={'number'}
          step={1}
          name={'participantsCount'}
          placeholder={t('meetings.participantsCount.placeholder')}
          label={t('meetings.participantsCount.label')}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.participantsCount?.toString()}
          error={errors.participantsCount?.toString()}
        />
      </div>
      {allowRecordings && (
        <div>
          <div>{t('meetings.record')}</div>
          <Switch size='large' name='record' onChange={handleChange} />
        </div>
      )}
    </div>
  );
};
