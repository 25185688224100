import { FC, useMemo, useState } from 'react';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { Button, CoursesIcon, Modal, Table, TableColumns } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useMemoizedCacheTag } from '../../../hooks/useMemoizedCacheTag';
import {
  CollaborationStatus,
  useCollaboratingOrganizationsQuery,
  useCreateCollaborationMutation,
  useDeleteCollaborationMutation,
} from '../../../client/bp-graphql-client-defs';
import { CollaborationsForm } from '../../../components/CollaborationsForm/CollaborationsForm';
import { Row } from '@tanstack/react-table';
import { showErrorToast } from '../../../utils/showErrorToast';
import { showSuccessToast } from '../../../utils/showSuccessToast';

type Collaboration = {
  uuid: string;
  name: string;
  collaborationStatus: CollaborationStatus;
  sourceOrganization: string;
};

export const InstitutionCollaborationsSubpage: FC = () => {
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const context = useMemoizedCacheTag('COLLABORATING');

  const [{ data }] = useCollaboratingOrganizationsQuery({ variables: { uuid: organizationUuid }, context });
  const { t } = useTranslation();
  const perms = usePermissionChecker();

  const [modalOpen, setModalOpen] = useState(false);

  const columns: TableColumns<Collaboration>[] = useMemo(() => {
    return [
      {
        header: t('common.name'),
        id: 'name',
        accessorKey: 'name',
        size: 200,
        canExpand: true,
      },
      {
        id: 'collaborationStatus',
        header: t('common.status'),
        cell: ({ row }: { row: Row<Collaboration> }) => {
          if (row.original.collaborationStatus === CollaborationStatus.Pending) return t('common.requested');
          if (row.original.collaborationStatus === CollaborationStatus.CollaborationCancelled)
            return t('collaborations.collaborationCancelled');
          if (row.original.collaborationStatus === CollaborationStatus.Collaboration)
            return t('collaborations.titleSingular');
          if (row.original.collaborationStatus === CollaborationStatus.NoCollaboration)
            return t('collaborations.notCollaboration');
          if (row.original.collaborationStatus === CollaborationStatus.NoCollaborationPossible)
            return t('collaborations.notPossible');
        },
        size: 150,
      },
    ];
  }, [t]);

  const tableData = useMemo(() => {
    return (
      ((data?.collaboratingOrganizations ?? []) as Collaboration[]).filter((collab) => {
        const filteredStates = [CollaborationStatus.NoCollaborationPossible, CollaborationStatus.NoCollaboration];
        return !filteredStates.includes(collab.collaborationStatus);
      }) ?? []
    );
  }, [data]);

  const [, createCollaboration] = useCreateCollaborationMutation();
  const [, deleteCollaboration] = useDeleteCollaborationMutation();

  const onAcceptClick = (collaboration: Collaboration) => {
    createCollaboration({ uuid: collaboration.uuid }, context)
      .then(() => {
        showSuccessToast(t('common.saved'));
      })
      .catch((resp) => {
        showErrorToast(resp.error);
      });
  };

  const onCancelClick = (collaboration: Collaboration) => {
    deleteCollaboration({ uuid: collaboration.uuid }, context)
      .then((resp) => {
        if ((resp.data?.deleteCollaboration.entitiesDeleted ?? 0) > 0) {
          showSuccessToast(t('common.deletedType', { type: t('collaborations.titleSingular') }));
        } else {
          showErrorToast(resp.error ?? { message: t('common.error'), name: '', graphQLErrors: [] });
        }
      })
      .catch((resp) => {
        showErrorToast(resp.error);
      });
  };

  return (
    <BpSubpage>
      <Table<Collaboration>
        columns={columns}
        data={tableData}
        sorting={[{ id: 'name', desc: false }]}
        showActionBar
        breakpoint='580px'
        isOnWhite={false}
        showBorderRadius
        showShadow
        actionBarSettings={{
          showAddButton: perms?.canCreateCollaboration({ uuid: organizationUuid }),
          addButtonText: t('collaborations.addCollaboration'),
          showExpertFilter: true,
        }}
        onAddClick={() => setModalOpen(true)}
        emptyStateSettings={{ icon: <CoursesIcon className='svg-icon primary-light' /> }}
        lastColWidth='175px'
        lastCol={(row) => {
          switch (row.original.collaborationStatus) {
            case CollaborationStatus.Collaboration:
              return (
                <Button
                  hierarchy={'secondary'}
                  onClick={() => alert(t('collaborations.cancelCollaborationNotPossible'))}
                >
                  {t('collaborations.cancelCollaboration')}
                </Button>
              );
            case CollaborationStatus.Pending:
              if (row.original.sourceOrganization !== organizationUuid) {
                return (
                  <Button hierarchy={'secondary'} onClick={() => onAcceptClick(row.original)}>
                    {t('collaborations.ackCollaboration')}
                  </Button>
                );
              } else {
                return (
                  <Button hierarchy={'secondary'} onClick={() => onCancelClick(row.original)}>
                    {t('collaborations.abortCollaboration')}
                  </Button>
                );
              }
            default:
              return <></>;
          }
        }}
      />

      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          title={t('common.addType', { type: t('collaborations.cooperationSchool') })}
        >
          <CollaborationsForm onClose={() => setModalOpen(false)} />
        </Modal>
      )}
    </BpSubpage>
  );
};
