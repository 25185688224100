import { useAuthClaims } from './useAuthClaims';
import { useContext, useRef } from 'react';
import { BpPermissionChecker } from '../utils/PermissionChecker';
import { OrganizationConfigContext } from '../context/OrganizationConfigContext';

export const usePermissionChecker = () => {
  const { pimAuthClaims } = useAuthClaims();
  const profile = pimAuthClaims.getProfile();
  const roles = pimAuthClaims.getRoles();
  const user = pimAuthClaims.getUser();
  const otherProfiles = pimAuthClaims.getOtherProfiles();
  const childProfiles = pimAuthClaims.getChildProfiles();
  const organization = pimAuthClaims.getOrganization();
  const omniOrganization = pimAuthClaims.getOmniOrganization();

  const permissionChecker = useRef<BpPermissionChecker | null>(null);

  const { allowStudentDirectMessages } = useContext(OrganizationConfigContext);

  if (!profile || !roles || !user || !omniOrganization || !organization) {
    console.warn('Insufficient claims for permission checker. Returning deny-all permission checker');
    permissionChecker.current = null;
  } else {
    permissionChecker.current = new BpPermissionChecker(
      {
        profile: profile,
        organization: organization,
        roles: roles,
        user: user,
        omniOrganization: omniOrganization,
        otherProfiles: otherProfiles,
        childProfiles: childProfiles,
      },
      allowStudentDirectMessages,
    );
  }

  return permissionChecker.current;
};
