import { BpEventType, EventsList } from '../EventsList/EventsList';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useBpCalendarEventsQuery } from '../../client/bp-graphql-client-defs';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { mapCalendarEventsToBpEventType } from '../../hooks/useCalendarEvents';

type ClassbookEventsProps = {
  day: dayjs.Dayjs;
  onEventClick: (bpEvent: BpEventType) => void;
  className?: string;
  selectedUuid: string | null;
};

export const ClassbookEvents = ({ day, onEventClick, selectedUuid, className }: ClassbookEventsProps) => {
  const bpEventsContext = useMemoizedCacheTag('EVENT');
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();

  const [{ data }] = useBpCalendarEventsQuery({
    variables: {
      where: {
        organization: {
          uuid: organizationUuid,
        },
        start_CONTAINS: day.format('YYYY-MM-DD'),
        categories_INCLUDES: 'lesson',
        attendeesConnection: {
          node: {
            uuid_IN: [pimAuthClaims.getProfile().uuid],
          },
        },
      },
    },
    context: bpEventsContext,
    requestPolicy: 'network-only',
  });

  const classbookEvents = useMemo(() => {
    return mapCalendarEventsToBpEventType(data?.calendarEvents ?? []) ?? [];
  }, [data]);

  return (
    <EventsList
      isOnWhite
      hideMonthHeadlines
      hideAddButton
      fullHeight
      showActive
      onEventClick={(bpEvent) => {
        onEventClick(bpEvent);
      }}
      events={classbookEvents}
      scrollToUuid={selectedUuid}
      className={className}
    />
  );
};
