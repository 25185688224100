import { AppointmentForm } from '../../../components/AppointmentForm/AppointmentForm';
import { BpCalendar } from '../../../components/BpCalendar/BpCalendar';
import { BpCard } from '../../../components/BpCard/BpCard';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { EventsList } from '../../../components/EventsList/EventsList';
import { FC, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCourseCalendarEvents } from '../../../hooks/useCalendarEvents';
import { findClosestEventToNow } from '../../../utils/dateCalculations';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { Grid, GridColumn, GridRow } from '@bp/ui-components';

export const CourseAppointmentsSubpage: FC = () => {
  const { uuid, courseUuid, add, edit } = useParams<{
    courseUuid: string;
    uuid: string;
    add: string;
    edit: string;
  }>();
  const navigate = useNavigate();
  const { pimAuthClaims } = useAuthClaims();

  const perms = usePermissionChecker();
  const isAddEditAllowed = perms?.canCreateBpCourseEvents({
    uuid: courseUuid ?? '',
    organization: { uuid: pimAuthClaims.getOrganizationUuid() },
  });
  const events = useCourseCalendarEvents(courseUuid ?? '');
  const [scrollUuid, setScrollUuid] = useState<string | null>(findClosestEventToNow(events)?.uuid ?? null);
  const [randomRedrawString, setRandomRedrawString] = useState<string>('');

  const showForm = useMemo(() => {
    return (isAddEditAllowed && (add || edit)) ?? false;
  }, [isAddEditAllowed, add, edit]);

  const appointmentUuid = useMemo(() => {
    return uuid ?? false;
  }, [uuid]);

  return (
    <BpSubpage>
      {showForm && courseUuid ? (
        <AppointmentForm
          courseUuid={courseUuid}
          appointmentUuid={appointmentUuid ? appointmentUuid : undefined}
          onClose={() => {
            navigate(`/courses/${courseUuid}/appointments/`);
          }}
        />
      ) : (
        <Grid>
          <GridRow mobileGap='var(--grid-column-gap)'>
            <GridColumn width={4}>
              <BpCard className='pb-6 pr-6 pl-6' noPadding showBorder hideBackground>
                <BpCalendar
                  events={events}
                  showAddButton={false}
                  eventClick={(uuid) => {
                    setRandomRedrawString(Date.now().toString());
                    setScrollUuid(uuid);
                  }}
                />
              </BpCard>
            </GridColumn>
            <GridColumn width={8}>
              <EventsList
                courseUuid={courseUuid}
                fullHeight
                showHeader
                events={events}
                scrollToUuid={scrollUuid}
                randomRedrawString={randomRedrawString}
                onAddClick={() => {
                  navigate(`/courses/${courseUuid}/appointments/create`);
                }}
                onEdit={
                  isAddEditAllowed
                    ? (uuid) => {
                        navigate(`/courses/${courseUuid}/appointments/edit/${uuid}`);
                      }
                    : undefined
                }
              />
            </GridColumn>
          </GridRow>
        </Grid>
      )}
    </BpSubpage>
  );
};
