import { EventRecurrence } from '@bp/bp-graphql-types';
import i18n from 'i18next';

function getRecurrenceEventText(recurrence: EventRecurrence | null | undefined) {
  let text = '';
  if (recurrence === EventRecurrence.Daily) text = i18n.t('appointments.recurrences.daily');
  else if (recurrence === EventRecurrence.Monthly) text = i18n.t('appointments.recurrences.monthly');
  else if (recurrence === EventRecurrence.Weekly) text = i18n.t('appointments.recurrences.weekly');
  else if (recurrence === EventRecurrence.Workdays) text = i18n.t('appointments.recurrences.workdays');
  else if (recurrence === EventRecurrence.Yearly) text = i18n.t('appointments.recurrences.yearly');
  return text;
}

function getCategoryText(categories: string[]) {
  let text = '';

  categories.forEach((category) => {
    if (category === 'exam') {
      text = i18n.t('events.categories.exam');
    } else if (category === 'event') {
      text = i18n.t('events.categories.event');
    } else if (category === 'lesson') {
      text = i18n.t('events.categories.lesson');
    }
  });

  return text;
}
export { getCategoryText, getRecurrenceEventText };
