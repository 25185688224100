import { GroupsIcon, Table, useDefaultSorting } from '@bp/ui-components';
import { Row } from '@tanstack/react-table';
import { BpLink } from '../BpLink/BpLink';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export type GroupsPreviewTableType = {
  uuid: string;
  name: string;
  meetingInProgress: boolean;
  count?: number;
  newMaterial: boolean;
};

type GroupsPreviewTableProps = {
  data: GroupsPreviewTableType[];
  onNavigate: (uuid: string) => void;
};

export const GroupsPreviewTable: FC<GroupsPreviewTableProps> = ({ data, onNavigate }) => {
  const { t } = useTranslation();
  const { sorting, onSortingChange } = useDefaultSorting();

  return (
    <Table<GroupsPreviewTableType>
      hideHeader
      showSort
      sorting={sorting}
      onSortingChange={onSortingChange}
      customPadding='var(--spacing-6)'
      customRowHeight='var(--list-row-height)'
      customLastColSpacing='0'
      breakpoint={null}
      data={data}
      columns={[
        {
          id: 'name',
          accessorKey: 'name',
          cell: ({ row }: { row: Row<GroupsPreviewTableType> }) => (
            <BpLink
              value={row.original.name}
              onNavigate={() => onNavigate(row.original.uuid)}
              inProgress={row.original.meetingInProgress}
              inProgressHint={t('groups.activeMeetingHint')}
              counter={row.original.count}
              counterHint={t('groups.newSubmissionsHint')}
              hasNews={row.original.newMaterial}
              hasNewsHint={t('groups.newMessageHint')}
            />
          ),
          canExpand: true,
        },
      ]}
      emptyStateSettings={{
        icon: <GroupsIcon className='svg-icon primary-light' />,
        size: 'small',
        title: '',
        subtitle: t('groups.noGroups'),
        padding: 'xl',
      }}
    />
  );
};
