import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@bp/ui-components';
import { AlertDialog } from '../components/AlertDialog/AlertDialog';

interface UseConfirmProps {
  defaultTitle?: string;
  defaultMessage?: string;
  defaultConfirmText?: string;
  defaultCancelText?: string;
}

interface ConfirmProps {
  title?: string;
  message?: string;
  confirmText?: string;
  cancelText?: string;
}

export const useConfirm = (props?: UseConfirmProps) => {
  const [promise, setPromise] = useState<null | { resolve: (value: boolean) => void }>(null);
  const [confirmText, setConfirmText] = useState<string | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [cancelText, setCancelText] = useState<string | null>(null);

  const { t } = useTranslation();
  const confirm = (props?: ConfirmProps) => {
    if (props?.title) {
      setTitle(props?.title);
    }
    if (props?.message) {
      setMessage(props?.message);
    }
    if (props?.confirmText) {
      setConfirmText(props?.confirmText);
    }
    if (props?.cancelText) {
      setCancelText(props?.cancelText);
    }
    return new Promise((resolve, reject) => {
      setPromise({ resolve });
    });
  };

  useEffect(() => {
    setConfirmText(props?.defaultConfirmText ?? null);
  }, [props?.defaultConfirmText]);

  useEffect(() => {
    setTitle(props?.defaultTitle ?? null);
  }, [props?.defaultTitle]);

  useEffect(() => {
    setMessage(props?.defaultMessage ?? null);
  }, [props?.defaultMessage]);

  useEffect(() => {
    setCancelText(props?.defaultCancelText ?? null);
  }, [props?.defaultCancelText]);

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };

  const ConfirmationDialog = () => (
    <AlertDialog
      isOpen={promise !== null}
      title={title ?? ''}
      actions={
        <>
          <Button hierarchy={'tertiary'} onClick={handleCancel}>
            {cancelText ?? t('common.cancel')}
          </Button>
          <Button hierarchy={'primary'} onClick={handleConfirm}>
            {confirmText ?? t('common.confirm')}
          </Button>
        </>
      }
    >
      {message}
    </AlertDialog>
  );
  return { ConfirmationDialog, confirm };
};
