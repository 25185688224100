import { useAuthClaims } from '../useAuthClaims';
import { useMemoizedCacheTag } from '../useMemoizedCacheTag';
import { organizationRolesAsString } from '../../utils/organizationRolesAsString';
import { RoomNames } from '../../utils/matrixClient';
import { useBpProfileQuery } from '../../client/bp-graphql-client-defs';

export type RoomInformation = {
  displayName: string;
  type: RoomNames;
  profile?: { name: string; uuid: string; roleName: string };
};

export const useMatrixNameResolver = () => {
  const context = useMemoizedCacheTag('PROFILE');
  const [{ data: profileData }] = useBpProfileQuery({ context });

  const { pimAuthClaims } = useAuthClaims();
  const resolveRoomName = (name: string): RoomInformation | null => {
    const parts = name.split(':')[0].split('_');
    if (parts[0] === RoomNames.Direct && profileData) {
      const profile1 = profileData.profiles.find((profile) => {
        return profile.uuid === parts[1];
      });
      const profile2 = profileData.profiles.find((profile) => {
        return profile.uuid === parts[2];
      });
      const profileToShow = pimAuthClaims.getProfile().uuid === profile1?.uuid ? profile2 : profile1;
      if (profileToShow) {
        return {
          displayName: `${profileToShow.firstName} ${profileToShow.lastName}`,
          type: RoomNames.Direct,
          profile: {
            uuid: profileToShow.uuid,
            name: profileToShow.firstName ?? '',
            roleName: organizationRolesAsString(profileToShow.organizationRoles),
          },
        };
      }
    }
    return {
      displayName: name,
      type: RoomNames.Unknown,
    };
  };

  return { resolveRoomName };
};
