import { useTranslation } from 'react-i18next';
import { MatrixChat } from '../../components/MatrixChat/MatrixChat';
import { BpPage } from 'components/BpPage/BpPage';
import { useParams } from 'react-router-dom';
import { getMatrixRoomIdWithServerName } from '../../utils/matrixRoomId';
import { useEffect, useState } from 'react';

export function MessagesPage() {
  const { t } = useTranslation();
  const { roomId } = useParams();

  const [matrixRoomId, setMatrixRoomId] = useState<string>();

  useEffect(() => {
    if (roomId) {
      getMatrixRoomIdWithServerName(roomId).then((_matrixRoomId) => {
        if (_matrixRoomId) {
          setMatrixRoomId(_matrixRoomId);
        }
      });
    }
  }, [roomId]);

  return (
    <BpPage title={t('messages.title')}>
      <MatrixChat matrixRoomId={matrixRoomId} />
    </BpPage>
  );
}
