import { Button, Input, Select, SelectOptionType, Switch } from '@bp/ui-components';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './InstantMeetings.module.scss';
import { CalendarEventCreateInput, useBpCreateCalendarEventMutation } from '../../../client/bp-graphql-client-defs';
import { connectByUuid } from '../../../utils/connectLib';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import dayjs from 'dayjs';
import { useMemoizedCacheTag } from '../../../hooks/useMemoizedCacheTag';
import { useDays } from '../../../hooks/useDays';
import { showErrorToast } from '../../../utils/showErrorToast';
import { useParams } from 'react-router-dom';
import { startAndJoinMeeting } from './startAndJoinMeeting';
import i18next from 'i18next';

export type InstantMeeting = {
  participantsCount?: number;
  duration: string;
  record: boolean;
  service: string;
};

export function InstantMeetings({ handleClose }: { handleClose: () => void }) {
  const { courseUuid, groupUuid } = useParams<{ courseUuid: string; groupUuid: string }>();

  const initialValues: InstantMeeting = {
    record: false,
    service: '',
    duration: '',
  };

  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const context = useMemoizedCacheTag('EVENT');
  const { ensureDay } = useDays();
  const [, bpCreateEvents] = useBpCreateCalendarEventMutation();

  async function handleSubmit(values: InstantMeeting, formikHelpers: FormikHelpers<InstantMeeting>) {
    const day = await ensureDay(new Date());

    const input: CalendarEventCreateInput = {
      organization: connectByUuid(pimAuthClaims.getOrganizationUuid()),
      holder: { Group: connectByUuid(groupUuid ?? courseUuid) },
      start: dayjs().toISOString(),
      endTime: dayjs().add(dayjs.duration(values.duration ? +values.duration : 0, 'seconds')),
      duration: values.duration?.toString() ?? '0',
      startTime: dayjs(),
      days: { connect: [{ where: { node: { uuid: day?.uuid } } }] },
      owner: connectByUuid(pimAuthClaims.getProfile().uuid ?? ''),
      maxAttendees: values.participantsCount ?? 1,
      recordVirtualEvent: values.record ?? false,
      categories: values.service ? ['virtual', values.service] : undefined, // 'virtual' MUSS mit bei, das backend prüft danach
    };

    const { error, data } = await bpCreateEvents({ input }, context);

    if (error) {
      showErrorToast(error);
    } else {
      formikHelpers.resetForm();
      handleClose();

      const joinSuccess =
        data?.createCalendarEvents.calendarEvents &&
        data?.createCalendarEvents.calendarEvents?.length > 0 &&
        (await startAndJoinMeeting(
          data.createCalendarEvents.calendarEvents[0],
          pimAuthClaims,
          groupUuid ?? courseUuid,
        ));

      if (!joinSuccess)
        showErrorToast({
          message: i18next.t('meetings.noVirtualLocation'),
          name: '',
          graphQLErrors: [],
        });
    }
  }

  function getServiceOptions() {
    return [
      { value: 'bbb', label: 'Big Blue Button' },
      { value: 'zoom', label: 'Zoom' },
    ];
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formik) => (
        <Form>
          <div className={styles.wrapper}>
            <Select
              name='service'
              options={getServiceOptions()}
              placeholder='Service auswählen'
              required={true}
              label='Über welchen Service wollen Sie die Besprechung starten?'
              onChange={(opt) => formik.setFieldValue('service', (opt as SelectOptionType).value)}
              error={formik.errors.service}
              onBlur={formik.handleBlur}
            />
            {formik.values.service && (
              <>
                <hr style={{ width: '100%' }} />
                <div className={styles.flex}>
                  <Input
                    name='participantsCount'
                    placeholder={t('meetings.participantsCount.placeholder')}
                    label={t('meetings.participantsCount.label')}
                    onChange={(ev) => formik.setFieldValue('participantsCount', +ev.target.value)}
                    onBlur={formik.handleBlur}
                    value={formik.values.participantsCount}
                    error={formik.errors.participantsCount}
                  />
                  <Input
                    name='duration'
                    placeholder='in Minuten'
                    label={t('meetings.expectedDuration')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.duration?.toString() as string}
                    error={formik.errors.duration}
                  />
                </div>
                <div className={`${styles.flex} ${styles['no-column']}`}>
                  <div>{t('meetings.record')}</div>
                  <Switch size='large' name='record' onChange={formik.handleChange} />
                </div>
                <div>
                  <Button type='submit' hierarchy='primary' fullWidth={true}>
                    {t('meetings.start')}
                  </Button>
                  <Button type='submit' hierarchy='primary' fullWidth={true}>
                    {t('meetings.startAndJoin')}
                  </Button>
                </div>
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
