import { Form, Formik } from 'formik';
import { useUpdateZoomsMutation, useZoomsQuery } from '../../client/bp-graphql-client-defs';
import { DatePicker, Input } from '@bp/ui-components';
import { LicensedProduct } from '../../pages/Institution/subpages/InstitutionOverviewSubpage';
import { useTranslation } from 'react-i18next';
import { ensureDate } from '../../utils/dateCalculations';
import { showErrorToast } from '../../utils/showErrorToast';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { validationSchema } from './zoomFormSchema';

type ZoomValuesType = {
  uuid: string;
  oauthAccountId?: string | null;
  oauthClientId?: string | null;
  oauthClientSecret?: string | null;
  validUntil: string;
};

export const ZoomForm = ({ data, closeModal }: { data: LicensedProduct; closeModal: () => void }) => {
  const { t } = useTranslation();
  const perms = usePermissionChecker();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const [zoomsQueryResult] = useZoomsQuery({ variables: { where: { uuid: data.uuid } } });
  const [, updateZooms] = useUpdateZoomsMutation();
  const handleClose = () => {
    closeModal();
  };

  const initialValues: ZoomValuesType = {
    uuid: zoomsQueryResult.data?.zooms[0].uuid ?? '',
    oauthAccountId: zoomsQueryResult.data?.zooms[0].oauthAccountId ?? '',
    oauthClientId: zoomsQueryResult.data?.zooms[0].oauthClientId ?? '',
    oauthClientSecret: zoomsQueryResult.data?.zooms[0].oauthClientSecret ?? '',
    validUntil: ensureDate(
      zoomsQueryResult.data?.zooms[0].organizationConnection.edges[0].properties.notAfter,
    ).toUTCString(),
  };

  const onSubmit = async (values: ZoomValuesType) => {
    if (perms?.canUpdateZoom({ uuid: organizationUuid })) {
      const resp = await updateZooms({
        update: {
          oauthAccountId: values.oauthAccountId,
          oauthClientId: values.oauthClientId,
          oauthClientSecret: values.oauthClientSecret,
          organization: {
            update: {
              edge: {
                notAfter: values.validUntil,
              },
            },
          },
        },
        where: { uuid: data.uuid },
      });

      if (resp.error) {
        showErrorToast(resp.error);
      } else {
        showSuccessToast(t('common.saved'));
        handleClose();
      }
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ resetForm, setFieldValue, handleChange, handleBlur, values, isSubmitting, errors }) => {
        return (
          <>
            <Form>
              <DatePicker
                label={t('common.validUntil')}
                onChange={(e) => {
                  setFieldValue('validUntil', e?.toUTCString());
                }}
                value={ensureDate(values.validUntil)}
                name={'validUntil'}
                showMonthYearDropdown
              />
              <Input
                className='mb-2'
                label={t('settings.zoomOAuthAccountId')}
                name={'oauthAccountId'}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.oauthAccountId?.toString()}
                error={errors.oauthAccountId}
              />
              <Input
                className='mb-2'
                label={t('settings.zoomOAuthClientId')}
                name={'oauthClientId'}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.oauthClientId?.toString()}
                error={errors.oauthClientId}
              />
              <Input
                className='mb-2'
                label={t('settings.zoomOauthClientSecret')}
                name={'oauthClientSecret'}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.oauthClientSecret?.toString()}
                error={errors.oauthClientSecret}
              />

              <ModalBottomButtons
                errors={errors}
                closeButton={{
                  callback: () => {
                    resetForm();
                    handleClose();
                  },
                }}
                isLoading={isSubmitting}
              />
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
