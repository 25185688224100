import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationTabs, NavigationTabsType } from '../../components/NavigationTabs/NavigationTabs';
import { LazyLoader } from '@bp/ui-components';
import { Outlet } from 'react-router-dom';
import { BpPage } from '../../components/BpPage/BpPage';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../hooks/useAuthClaims';

export function InstitutionPage() {
  const { t } = useTranslation();
  const perms = usePermissionChecker();
  const organization = useAuthClaims().pimAuthClaims.getOrganizationUuid();

  const tabs: NavigationTabsType[] = [
    {
      title: t('overview.title'),
      path: '/institution',
      pathMatchEnd: true,
    },
    ...(perms?.canCreateProfile({ uuid: organization })
      ? [
          {
            title: t('persons.title'),
            path: 'persons',
          },
        ]
      : []),
    ...(perms?.canListInstitutionGroups({ uuid: organization })
      ? [
          {
            title: t('groups.title'),
            path: 'groups',
          },
        ]
      : []),
    ...(perms?.canListInstitutionCourses({ uuid: organization })
      ? [
          {
            title: t('courses.title'),
            path: 'courses',
          },
        ]
      : []),
    ...(perms?.canListSubjects({ uuid: organization })
      ? [
          {
            title: t('subjects.title'),
            path: 'subjects',
          },
        ]
      : []),
    ...(perms?.canListSchoolyears({ uuid: organization })
      ? [
          {
            title: t('schoolyears.title'),
            path: 'schoolyears',
          },
        ]
      : []),
  ];

  return (
    <BpPage title={t('institution.myInstitution')}>
      <NavigationTabs tabs={tabs} className='mb-6' />
      <Suspense fallback={<LazyLoader embedded transparent forceHeight='45vh' />}>{<Outlet />}</Suspense>
    </BpPage>
  );
}
