import dayjs from 'dayjs';
import { LazyLoader, Modal } from '@bp/ui-components';
import {
  useBpAssignmentsQuery,
  useBpDeleteGroupMutation,
  useProfileCoursesQuery,
} from '../../client/bp-graphql-client-defs';
import { BpPage } from '../../components/BpPage/BpPage';
import { CourseForm } from '../../components/CourseForm/CourseForm';
import { CoursesTableStudent } from '../../components/CoursesTable/CoursesTableStudent';
import { CoursesTableTeacher, CourseTableType } from '../../components/CoursesTable/CoursesTableTeacher';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useConfirm } from '../../hooks/useConfirm';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { Suspense, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CourseSettingsForm } from '../../components/CourseSettingsForm/CourseSettingsForm';
import { OrganizationConfigContext } from '../../context/OrganizationConfigContext';
import { useGetMatrixRooms } from 'hooks/matrix/useGetMatrixRooms';
import { useGroupOrCoursesOnlineEvents } from '../../hooks/useCalendarEvents';

export const CoursesPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const perms = usePermissionChecker();
  const { pimAuthClaims, updateClaims } = useAuthClaims();

  const context = useMemoizedCacheTag('COURSE');
  const assignmentContext = useMemoizedCacheTag('ASSIGNMENT');

  const { currentSchoolYear } = useContext(OrganizationConfigContext);

  const [claimsTrigger, setClaimsTrigger] = useState<number>(0);

  const [, deleteCourse] = useBpDeleteGroupMutation();

  const [{ data }] = useProfileCoursesQuery({
    variables: {
      organizations: pimAuthClaims.getAllowedOrganizationUuids(),
      profile: pimAuthClaims.getProfile().uuid,
    },
    context,
  });

  const onlineEvents = useGroupOrCoursesOnlineEvents((data?.groups ?? []).map((c) => c.uuid) ?? []);

  const [{ data: assignmentsQueryResult }] = useBpAssignmentsQuery({
    context: assignmentContext,
  });

  const rooms = useGetMatrixRooms();

  const tableData: CourseTableType[] = useMemo(() => {
    return (
      data?.groups
        .filter((c) => c.schoolYear?.uuid === currentSchoolYear.uuid || c.isCommunity) // TODO: currentSchoolyear
        .map((course) => {
          const notificationRoom = rooms?.find((room) => room.name.includes(course.uuid));
          let newMessages = false;

          if (notificationRoom) {
            newMessages = notificationRoom.getRoomUnreadNotificationCount() > 0;
          }

          if (!newMessages) {
            assignmentsQueryResult?.assignments
              .filter((assignments) => {
                return assignments.holder.group.uuid === course.uuid;
              })
              .forEach((assigment) => {
                assigment.submissions.forEach((submission) => {
                  const room = rooms?.find((room) => {
                    return room.name.includes(submission.uuid);
                  });
                  if (room && !newMessages) {
                    newMessages = room.getRoomUnreadNotificationCount() > 0;
                  }
                });
              });
          }

          return {
            uuid: course.uuid,
            organizationUuid: course.organization.uuid,
            name: course.name,
            meetingInProgress:
              onlineEvents.find((v) => v.originUuid === course.uuid)?.virtualLocations.find((v) => v.running)
                ?.running ?? false,
            count: 0,
            newMessage: newMessages,
            community: course.isCommunity,
            source:
              course.managedBy !== 'bp'
                ? course.managedBy
                : course.admins.map((admin) => admin.displayNameShort).toString(),
            nextcloud: {
              enabled: course.externalServices?.nextcloud?.enabled ?? false,
              lastSync: course.externalServices?.nextcloud?.lastSync
                ? dayjs(course.externalServices?.nextcloud.lastSync)
                : undefined,
            },
            threema: {
              enabled: course.externalServices?.threema?.enabled ?? false,
              lastSync: course.externalServices?.threema?.lastSync
                ? dayjs(course.externalServices?.threema.lastSync)
                : undefined,
            },
          };
        }) ?? []
    );
  }, [data?.groups, currentSchoolYear.uuid, rooms, onlineEvents, assignmentsQueryResult?.assignments]);

  const [modalMode, setModalMode] = useState<{
    open: boolean;
    mode: 'create' | 'edit' | '';
    courseUuid?: string | null;
  }>({ open: false, mode: '', courseUuid: null });

  const { ConfirmationDialog, confirm: confirmDelete } = useConfirm({
    defaultTitle: t('common.delete'),
    defaultConfirmText: t('delete.confirmText'),
  });

  const handleAdd = () => {
    setModalMode({ open: true, mode: 'create' });
  };

  const handleEdit = (uuid: string) => {
    setModalMode({ open: true, mode: 'edit', courseUuid: uuid });
  };

  const handleDelete = async (uuid: string) => {
    await deleteCourse(
      {
        id: uuid,
      },
      context,
    );
  };

  const [settingsModalOpen, setSettingsModalOpen] = useState<{
    open: boolean;
    courseUuid: string | null;
  }>({ open: false, courseUuid: null });

  const handleSettings = (courseUuid: string) => {
    setSettingsModalOpen({ open: true, courseUuid: courseUuid });
  };

  const handleSettingsClose = () => {
    setSettingsModalOpen({ open: false, courseUuid: null });
  };

  return (
    <BpPage title={t('courses.myCourses', { year: currentSchoolYear?.name })}>
      {perms?.canCreateCourse() ? (
        <CoursesTableTeacher
          data={tableData}
          onNavigate={(uuid) => navigate('/courses/' + uuid)}
          onAdd={handleAdd}
          onEdit={handleEdit}
          onDelete={async (uuid) => {
            const res = await confirmDelete();
            if (res) {
              await handleDelete(uuid);
            }
          }}
          onSettings={(uuid) => handleSettings(uuid)}
        />
      ) : (
        <CoursesTableStudent data={tableData} onNavigate={(uuid) => navigate('/courses/' + uuid)} />
      )}

      <Modal
        isOpen={modalMode.open}
        onRequestClose={() => setModalMode({ open: false, mode: '', courseUuid: null })}
        title={modalMode.mode === 'edit' ? t('courses.editCourse') : t('courses.addCourse')}
        hideFooter
      >
        <Suspense fallback={<LazyLoader embedded forceHeight='30vh' />}>
          <CourseForm
            courseUuid={modalMode.courseUuid ?? ''}
            onClose={async () => {
              await updateClaims(() => setClaimsTrigger(claimsTrigger + 1));
              setModalMode({ open: false, mode: '', courseUuid: null });
            }}
          />
        </Suspense>
      </Modal>

      <Modal
        title={t('courses.coursesSettings')}
        isOpen={settingsModalOpen.open}
        shouldCloseOnEsc={true}
        onRequestClose={handleSettingsClose}
      >
        <CourseSettingsForm onClose={handleSettingsClose} courseUuid={settingsModalOpen.courseUuid} />
      </Modal>

      <ConfirmationDialog />
    </BpPage>
  );
};
