import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const PageNotFoundPage: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h1>404</h1>
      <p>{t('pageNotFound.title')}</p>
    </div>
  );
};
