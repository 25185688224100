import { AlertDialog, Button, DotsVerticalIcon, Dropdown, DropdownMenu, Table } from '@bp/ui-components';
import { Row } from '@tanstack/react-table';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { BpLink } from 'components/BpLink/BpLink';

export type WorkMaterialsPreviewTableType = {
  uuid: string;
  name: string;
  relevantFrom: string;
  relevantTo: string;
  visibleFrom: string;
  visibleAfterVisibleTo: boolean;
};

type WorkMaterialsPreviewTableProps = {
  data: WorkMaterialsPreviewTableType[];
  onNavigate: (uuid: string) => void;
  onEdit: (uuid: string) => void;
  onDelete: (uuid: string) => void;
};

export const WorkMaterialsPreviewTable: FC<WorkMaterialsPreviewTableProps> = ({
  data,
  onNavigate,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();

  const today = dayjs();

  return (
    <Table<WorkMaterialsPreviewTableType>
      maxHeight={220}
      className='hide-gutter'
      canScroll
      hideHeader
      showSort
      customPadding='var(--spacing-6)'
      customRowHeight='var(--list-row-height)'
      customLastColSpacing='var(--spacing-3)'
      lastColWidth='130px'
      breakpoint={null}
      data={data}
      columns={[
        {
          id: 'name',
          accessorKey: 'name',
          cell: ({ row }: { row: Row<WorkMaterialsPreviewTableType> }) => (
            <BpLink value={row.original.name} onNavigate={() => onNavigate?.(row.original.uuid)} maxWidth='268px' />
          ),
          canExpand: true,
        },
      ]}
      emptyStateSettings={{
        hideIcon: true,
        size: 'small',
        title: '',
        subtitle: t('workmaterials.noWorkmaterials'),
        forcedHeight: '150px',
        fitParent: true,
      }}
      lastCol={(row: Row<WorkMaterialsPreviewTableType>) => {
        const isRelevantText = t('workmaterials.relevant');
        const visibleText = t('workmaterials.visible');
        const notVisibleText = t('workmaterials.notVisible');

        const isRelevant =
          today.isBetween(row.original.relevantFrom, row.original.relevantTo) ||
          today.startOf('day').isSame(dayjs(row.original.relevantFrom).startOf('day')) ||
          today.startOf('day').isSame(dayjs(row.original.relevantTo).startOf('day'));

        const notVisible =
          !isRelevant &&
          !dayjs(row.original.visibleFrom).startOf('day').isBefore(dayjs(row.original.relevantFrom).startOf('day')) &&
          !(row.original.visibleAfterVisibleTo && dayjs(row.original.visibleFrom).startOf('day').isBefore(dayjs()));

        return (
          <div className='bp__last-col'>
            {isRelevant && <div style={{ color: 'var(--color-success)' }}>{isRelevantText}</div>}
            {notVisible && <div>{notVisibleText}</div>}
            {!isRelevant && !notVisible && <div>{visibleText}</div>}
            <Dropdown trigger={<Button hierarchy='ghost' icon={<DotsVerticalIcon className='svg-icon' />} />}>
              <DropdownMenu
                data={[
                  {
                    label: t('common.edit'),
                    onClick: () => {
                      onEdit(row.original.uuid);
                    },
                  },
                  {
                    type: 'ruler',
                    label: '',
                  },
                  {
                    type: 'component',
                    label: t('common.delete'),
                    node: (
                      <AlertDialog
                        title={t('delete.title')}
                        confirmText={t('delete.title')}
                        cancelText={t('delete.abort')}
                        onConfirm={() => {
                          onDelete(row.original.uuid);
                        }}
                        trigger={t('common.delete')}
                      >
                        {t('delete.confirmText')}
                      </AlertDialog>
                    ),
                  },
                ]}
              />
            </Dropdown>
          </div>
        );
      }}
    />
  );
};
