import { useAuthClaims } from '../useAuthClaims';
import {
  useBpCalendarEventsQuery,
  useBpCollaboratingOrganizationsQuery,
  useBpSubmissionsQuery,
  useGroupAsGroupsQuery,
} from '../../client/bp-graphql-client-defs';
import { isNotificationType, NotificationReasons, NotificationType } from '../../utils/matrixClient';
import { useEffect, useState } from 'react';
import { mapNotification } from '../../components/Header/components/Notifications/mapNotification';
import { useMemoizedCacheTag } from '../useMemoizedCacheTag';
import { useMatrixAvailable } from './useMatrixAvailable';
import { useRefreshOnEvent } from './useRefreshOnEvent';
import { useMatrixClient } from './useMatrixClient';
import { GroupType } from '@bp/bp-graphql-types';
import { MatrixEvent } from 'matrix-js-sdk';

export enum BpRoomType {
  DM = 'dm',
  GROUP = 'group',
  COURSE = 'course',
  ORGANIZATION = 'organization',
  SUBMISSION = 'submission',
}

export const useNotifications = () => {
  const { pimAuthClaims } = useAuthClaims();
  const online = useMatrixAvailable();
  const refresh = useRefreshOnEvent();

  const matrixClient = useMatrixClient();

  const rooms = matrixClient?.getVisibleRooms().filter((room) => {
    const parts = room?.name.split(':')[0].split('_');
    const roomType: BpRoomType = parts[0] as BpRoomType;
    return roomType !== BpRoomType.DM;
  });

  const [notifications, setNotifications] = useState<NotificationType[]>([]);

  const profileUuid = pimAuthClaims.getProfile().uuid;
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const otherProfilesUuids = pimAuthClaims.getOtherProfiles().map((p) => p.uuid);

  const groupContext = useMemoizedCacheTag('GROUP');
  const submissionContext = useMemoizedCacheTag('SUBMISSION');

  const [{ data: profileSubmissions }, refetchSubmissions] = useBpSubmissionsQuery({
    variables: {
      where: {
        ownerConnection: {
          node: {
            uuid_IN: [profileUuid, ...otherProfilesUuids],
          },
        },
      },
    },
    context: submissionContext,
  });

  const [{ data: groupsData }] = useGroupAsGroupsQuery({
    variables: {
      where: {
        OR: [
          { editors_SOME: { uuid_IN: [profileUuid, ...otherProfilesUuids] } },
          { viewers_SOME: { uuid_IN: [profileUuid, ...otherProfilesUuids] } },
        ],
      },
    },
    context: groupContext,
  });
  const [{ data: calendarEventsData }] = useBpCalendarEventsQuery({
    variables: {
      where: {
        attendees_SOME: { uuid: profileUuid },
      },
    },
    context: groupContext,
  });

  const [{ data: organizationsData }] = useBpCollaboratingOrganizationsQuery({
    variables: { uuid: pimAuthClaims.getOrganizationUuid() },
  });

  useEffect(() => {
    function createBaseData(roomType: BpRoomType, typeUuid: string, groupUuid = '') {
      const group = groupsData?.groups.find((g) => g?.uuid === groupUuid);

      const baseData: Pick<
        NotificationType,
        'subjectUuid' | 'subjectName' | 'groupType' | 'groupUuid' | 'isCommunity' | 'assignmentUuid' | 'groupName'
      > = {
        subjectUuid: '',
        subjectName: '',
      };

      switch (roomType) {
        case BpRoomType.GROUP: {
          if (!group?.uuid) return;
          if (group) {
            baseData.subjectName = group.name;
            baseData.groupUuid = group.uuid;
            baseData.groupType = group.type === GroupType.Course ? BpRoomType.COURSE : BpRoomType.GROUP;
            baseData.groupName = group.name;
            baseData.isCommunity = group.isCommunity ?? false;
          }

          break;
        }

        case BpRoomType.SUBMISSION: {
          const s = profileSubmissions?.submissions.find((s) => s?.uuid === typeUuid);
          if (!s?.assignment[0]) return;

          baseData.subjectName = s?.assignment[0]?.title ?? '';
          baseData.subjectUuid = typeUuid;
          baseData.assignmentUuid = s?.assignment[0]?.uuid;
          baseData.groupUuid = s?.assignment[0]?.holder.group.uuid;

          break;
        }

        case BpRoomType.ORGANIZATION: {
          const collabOrga = organizationsData?.collaboratingOrganizations.find((o) => o.uuid === typeUuid);
          if (collabOrga) {
            baseData.subjectUuid = typeUuid;
            baseData.subjectName = collabOrga.name ?? '';
          } else {
            // this organization
            baseData.subjectUuid = pimAuthClaims.getOrganizationUuid();
            baseData.subjectName = pimAuthClaims.getOrganization().name;
          }
          break;
        }
      }

      return baseData;
    }

    const latestJoinEventByRoom: Record<string, number> = {};

    const _updateLatestJoinEventTS = (matrixEvent: MatrixEvent) => {
      const isJoinEvent = matrixEvent.getContent().membership === 'join';
      const eventTs = matrixEvent.getTs();
      const roomId = matrixEvent.getRoomId();

      if (isJoinEvent && roomId) {
        if (roomId in latestJoinEventByRoom) {
          // we have apparently already seen a 'join' event for this user in this room; take the latest
          const oldEventTs = latestJoinEventByRoom[roomId];

          if (eventTs > oldEventTs) {
            latestJoinEventByRoom[roomId] = eventTs;
          }
        } else {
          latestJoinEventByRoom[roomId] = eventTs;
        }
      }
    };

    const notifications: NotificationType[] = [];
    rooms?.forEach((room) => {
      const parts = room?.name.split(':')[0].split('_');
      const roomType: BpRoomType = parts[0] as BpRoomType;
      const groupUuid: string | undefined = parts[2];

      const typeUuid = parts[1];

      const baseData = createBaseData(roomType, typeUuid, groupUuid);

      const events = room?.getLiveTimeline().getEvents();

      for (const matrixEvent of events) {
        _updateLatestJoinEventTS(matrixEvent);

        const eventBody = matrixEvent.getContent().body;
        if (!eventBody) continue;

        const roomId = matrixEvent.getRoomId();

        let payload: NotificationType | null = null;
        try {
          payload = JSON.parse(eventBody);
        } catch {
          // must be a text message
          // the only place we use this are submissions
          if (
            eventBody &&
            // a teacher has no submissions, and only he / her should see this message
            !profileSubmissions?.submissions.find((s) => s.owner.uuid === pimAuthClaims.getProfile().uuid)
          ) {
            refetchSubmissions({ where: { uuid: groupUuid } });

            payload = {
              type: NotificationReasons.SubmissionMessage,
              date: matrixEvent.getDate() ?? new Date(),
              eventId: matrixEvent.getId(),
              roomId,
              content: eventBody,
            };
          }
        }

        if (isNotificationType(payload)) {
          // can be removed when typing ist consistent
          const type = payload.type?.charAt(0).toLowerCase() + payload.type?.slice(1);

          if (
            roomId &&
            // for events BEFORE the user joined the room show only selected events
            latestJoinEventByRoom[roomId] > matrixEvent.getTs() &&
            type !== NotificationReasons.Announcement &&
            type !== NotificationReasons.NewGroupMaterial &&
            type !== NotificationReasons.NewCourseMaterial
          )
            continue;

          const data: NotificationType = {
            date: matrixEvent.getDate() ?? new Date(),
            type: payload.type,
            eventId: matrixEvent.getId(),
            roomId: matrixEvent.getRoomId(),
          };

          if (type === NotificationReasons.Announcement) {
            if (baseData?.groupType === BpRoomType.GROUP) {
              payload.type = NotificationReasons.NewGroupAnnouncement;
            }

            if (baseData?.groupType === BpRoomType.COURSE) {
              payload.type = NotificationReasons.NewCourseAnnouncement;
            }

            if (roomType === BpRoomType.ORGANIZATION) {
              payload.type = NotificationReasons.NewOrganizationAnnouncement;
            }
          }

          if (type === NotificationReasons.EventCreated) {
            if (baseData?.groupType === BpRoomType.GROUP) {
              payload.type = NotificationReasons.GroupEventCreated;
            }

            if (baseData?.groupType === BpRoomType.COURSE) {
              payload.type = NotificationReasons.CourseEventCreated;
            }

            payload.title = groupsData?.groups.find((g) => g.uuid === groupUuid)?.name ?? 'No Name';
          }

          if (type === NotificationReasons.NewGroupMembership && baseData?.groupType === BpRoomType.COURSE) {
            payload.type = NotificationReasons.NewCourseMembership;
          }

          if (
            type === NotificationReasons.EventStarted ||
            type === NotificationReasons.EventEnded ||
            type === NotificationReasons.EventJoined
          ) {
            // TODO: handle organization and 1v1 meetings
            const event = calendarEventsData?.calendarEvents.find((evt) => evt.uuid === payload.subjectUuid);
            payload.groupUuid =
              event?.holderConnection.edges
                ?.filter((edge) => edge.node.__typename === 'Group')
                .map((edge) => edge.node.uuid)
                .shift() ?? '';
          }

          const { content, targetUrl } = mapNotification({ ...baseData, ...payload, organizationUuid });
          if (content === '') continue;

          if (content) {
            data.content = content;
            data.targetUrl = targetUrl;
            notifications.push(data);
          }
        }
      }
    });
    const sorted = notifications.sort((a, b) => b.date.getTime() - a.date.getTime());
    setNotifications(sorted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    online,
    refresh,
    organizationUuid,
    groupsData?.groups,
    profileSubmissions?.submissions,
    organizationsData?.collaboratingOrganizations,
  ]);

  return notifications;
};
