import { FC } from 'react';
import { useBpWorkMaterialsQuery } from '../../client/bp-graphql-client-defs';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useTranslation } from 'react-i18next';
import { BpCard } from '../../components/BpCard/BpCard';
import { FileTable } from '../FileTable/FileTable';
import { BpHeader } from '../../components/BpHeader/BpHeader';
import { BpTipTapText } from 'components/BpTipTapText/BpTipTapText';

export type MaterialDetailProps = {
  uuid: string;
  onClose: () => void;
};

export const MaterialDetail: FC<MaterialDetailProps> = ({ uuid, onClose }) => {
  const queryContext = useMemoizedCacheTag('WORKMATERIAL');
  const { t } = useTranslation();

  const [{ data }] = useBpWorkMaterialsQuery({ variables: { where: { uuid } }, context: queryContext });
  const material = data?.workMaterials[0];

  return (
    <>
      <BpHeader
        headline={material?.title ?? ''}
        actions={[{ text: t('common.back'), callback: () => onClose(), hierarchy: 'primary' }]}
      />
      <BpCard noPadding header={{ headline: t('common.content') }}>
        {material?.material.text && <BpTipTapText className='px-6 py-2' content={material.material.text} />}
        <FileTable files={material?.material.fileEntries ?? []} mode={'show'} />
      </BpCard>
    </>
  );
};
