import { ArrowHeadRightIcon, Button } from '@bp/ui-components';
import { useBpProfileQuery, useBpSubmissionsQuery } from '../../client/bp-graphql-client-defs';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { BpCard } from '../BpCard/BpCard';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import styles from './AnswersCard.module.scss';
import { BpTipTapText } from '../BpTipTapText/BpTipTapText';
import { type Submission } from '@bp/bp-graphql-types';

type AnswersCardProps = { assignmentUuid?: string; onNavigate: (submission: Submission) => void };

export const AnswersCard: FC<AnswersCardProps> = ({ assignmentUuid = '', onNavigate }) => {
  const { t } = useTranslation();

  const context = useMemoizedCacheTag('SUBMISSION');
  const [{ data }] = useBpSubmissionsQuery({
    context,
    variables: {
      where: {
        public: true,
        assignmentConnection_SINGLE: {
          node: {
            uuid: assignmentUuid,
          },
        },
      },
    },
  });

  const ownerUuids = data?.submissions.map((s) => s.owner.uuid);

  const profileContext = useMemoizedCacheTag('PROFILE');
  const [profilesNames] = useBpProfileQuery({
    context: profileContext,
    variables: {
      where: {
        uuid_IN: ownerUuids,
      },
    },
  });

  const onClick = (submission: Submission) => {
    onNavigate(submission);
  };

  return (
    <>
      {data?.submissions && data?.submissions.length > 0 && (
        <BpCard header={{ headline: t('submissions.publicised') }}>
          <>
            {data?.submissions.map((s) => {
              const profile = profilesNames.data?.profiles.find((p) => p.uuid === s.owner.uuid);
              const name = `${profile?.firstName} ${profile?.lastName}`;

              return (
                <div key={s.uuid} className={styles.answer}>
                  <div className={styles.top}>
                    <div className={styles.name}>
                      {t('submissions.answerFrom')} {name}
                    </div>
                    <Button
                      className={styles.button}
                      onClick={() => onClick(s as Submission)}
                      hierarchy='tertiary'
                      icon={<ArrowHeadRightIcon className='svg-icon' />}
                    />
                  </div>
                  <BpTipTapText embedded content={s.material.text ?? ''} />
                </div>
              );
            })}
          </>
        </BpCard>
      )}
    </>
  );
};
