import { useTranslation } from 'react-i18next';
import { EmptyState, Table, TableColumns } from '@bp/ui-components';
import { useMemo } from 'react';
import { niceDate } from '../../utils/dateCalculations';
import { BpLink } from '../BpLink/BpLink';

export type WorkMaterialType = { uuid: string; title: string; updated: string };

type WorkMaterialsListProps = {
  workMaterial: WorkMaterialType[];
  onNavigate?: (uuid: string) => void;
};

const WorkMaterialsList = ({ workMaterial, onNavigate }: WorkMaterialsListProps) => {
  const { t } = useTranslation();

  const memoizedData = useMemo((): WorkMaterialType[] => {
    return workMaterial.map((wm) => {
      return { uuid: wm.uuid, title: wm.title, updated: niceDate(wm.updated, 'medium', 'short') ?? '' };
    });
  }, [workMaterial]);

  function createColumns(): TableColumns<WorkMaterialType>[] {
    return [
      {
        id: 'title',
        size: 450,
        canExpand: true,
        header: t('common.name'),
        accessorKey: 'title',
        cell: ({ row }) => {
          return (
            <BpLink value={row.original.title} onNavigate={() => onNavigate?.(row.original.uuid)} maxWidth='740px' />
          );
        },
      },
      {
        id: 'updated',
        accessorKey: 'updated',
        header: t('common.updatedAt'),
      },
    ];
  }

  return memoizedData.length === 0 ? (
    <EmptyState
      subtitle={t('workmaterials.noWorkmaterials')}
      fitParent
      padding='xl'
      size='small'
      forcedHeight='220px'
    />
  ) : (
    <Table<WorkMaterialType>
      columns={createColumns()}
      data={memoizedData}
      hideHeader={memoizedData.length === 0}
      customPadding='var(--spacing-6)'
      breakpoint='580px'
      customRowHeight='var(--list-row-height)'
    />
  );
};

export { WorkMaterialsList };
