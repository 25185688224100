import { Form, Formik } from 'formik';
import {
  useCreateNextcloudsMutation,
  useNextcloudsQuery,
  useUpdateNextcloudsMutation,
} from '../../client/bp-graphql-client-defs';
import { DatePicker, Input } from '@bp/ui-components';
import { LicensedProduct } from '../../pages/Institution/subpages/InstitutionOverviewSubpage';
import { useTranslation } from 'react-i18next';
import { validationSchema } from './nextcloudFormSchema';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { ensureDate } from '../../utils/dateCalculations';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { showErrorToast } from '../../utils/showErrorToast';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';

type NextcloudValuesType = {
  adminPass?: string | null;
  adminUser?: string | null;
  serverUri?: string | null;
  serverAliase?: Array<string> | null;
  notBefore: string;
  notAfter: string;
};

type NextcloudFormProps = { data: LicensedProduct; closeModal: () => void };

export const NextcloudForm = ({ data, closeModal }: NextcloudFormProps) => {
  const { t } = useTranslation();
  const perms = usePermissionChecker();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const context = useMemoizedCacheTag('PRODUCTS');
  const [nextcloudsQueryResult] = useNextcloudsQuery({ variables: { where: { uuid: data.uuid ?? '0' } }, context });
  const currentBooking = nextcloudsQueryResult.data?.nextclouds.shift();

  const [, updateNextclouds] = useUpdateNextcloudsMutation();
  const [, createNextclouds] = useCreateNextcloudsMutation();
  const handleClose = () => {
    closeModal();
  };

  const initialValues: NextcloudValuesType = {
    adminPass: currentBooking?.adminPass ?? '',
    adminUser: currentBooking?.adminUser ?? '',
    serverUri: currentBooking?.serverUri ?? '',
    serverAliase: currentBooking?.serverAliase ?? [],
    notBefore: ensureDate(currentBooking?.organizationConnection.edges[0].properties.notBefore).toUTCString(),
    notAfter: ensureDate(currentBooking?.organizationConnection.edges[0].properties.notAfter).toUTCString(),
  };

  const onSubmit = async (values: NextcloudValuesType) => {
    if (perms?.canUpdateNextcloud({ uuid: organizationUuid })) {
      if (data.uuid === undefined) {
        const resp = await createNextclouds(
          {
            input: [
              {
                serverUri: values.serverUri,
                adminUser: values.adminUser,
                adminPass: values.adminPass,
                organization: {
                  connect: {
                    overwrite: true,
                    edge: {
                      notAfter: values.notAfter,
                      notBefore: values.notBefore,
                    },
                    where: { node: { uuid: organizationUuid } },
                  },
                },
              },
            ],
          },
          context,
        );

        if (resp.error) {
          showErrorToast(resp.error);
        } else {
          showSuccessToast(t('common.saved'));
          handleClose();
        }
      } else {
        const resp = await updateNextclouds(
          {
            update: {
              serverUri: values.serverUri,
              adminUser: values.adminUser,
              adminPass: values.adminPass,
              organization: {
                update: {
                  edge: {
                    notAfter: values.notAfter,
                    notBefore: values.notBefore,
                  },
                },
              },
            },
            where: { uuid: data.uuid },
          },
          context,
        );

        if (resp.error) {
          showErrorToast(resp.error);
        } else {
          showSuccessToast(t('common.saved'));
          handleClose();
        }
      }
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ resetForm, setFieldValue, handleChange, handleBlur, values, isSubmitting, errors }) => {
        const onClose = () => {
          resetForm();
          handleClose();
        };

        return (
          <>
            <Form>
              <DatePicker
                label={t('institution.bookedFromDate')}
                onChange={(e) => setFieldValue('notBefore', e?.toUTCString())}
                value={ensureDate(values.notBefore)}
                name={'notBefore'}
                showMonthYearDropdown
              />

              <DatePicker
                label={t('institution.bookedUntilDate')}
                onChange={(e) => setFieldValue('notAfter', e?.toUTCString())}
                value={ensureDate(values.notAfter)}
                name={'notAfter'}
                showMonthYearDropdown
              />
              <Input
                className='mb-2'
                required={true}
                label={t('settings.nextcloudServerUri')}
                name={'serverUri'}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.serverUri?.toString()}
                error={errors.serverUri}
              />
              <Input
                className='mb-2'
                required={true}
                label={t('settings.nextcloudAdminUser')}
                name={'adminUser'}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.adminUser?.toString()}
                error={errors.adminUser}
              />
              <Input
                className='mb-2'
                required={true}
                label={t('settings.nextcloudAdminPass')}
                name={'adminPass'}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.adminPass?.toString()}
                error={errors.adminPass}
              />

              <ModalBottomButtons errors={errors} closeButton={{ callback: onClose }} isLoading={isSubmitting} />
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
