import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Checkbox, Grid, GridColumn, GridRow, Input, Select, SelectOptionType } from '@bp/ui-components';
import { useCreateSelectOptions } from '../../hooks/useCreateSelectOptions';
import { MultiValue, SingleValue } from 'react-select';
import { CourseFormValues } from './CourseForm';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useSchoolYearsQuery, useSubjectsQuery } from '../../client/bp-graphql-client-defs';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';

const grades = Array(14)
  .fill(1)
  .map((element, index) => index.toString())
  .reverse();

export const CourseFormBaseData = () => {
  const { t } = useTranslation();
  const { handleChange, setFieldValue, values, errors, setFieldTouched, handleBlur } =
    useFormikContext<CourseFormValues>();
  const { pimAuthClaims } = useAuthClaims();
  const permissions = usePermissionChecker();
  const context = useMemoizedCacheTag('COURSE');

  const [{ data: schoolyearsData }] = useSchoolYearsQuery({
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
    },
    context,
  });

  const [{ data: subjectsData }] = useSubjectsQuery({
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
    },
    context,
  });

  const subjects = subjectsData?.subjects;
  const schoolyears = schoolyearsData?.schoolYears;

  const selectSubjectsOpts = useCreateSelectOptions(subjects, 'uuid', 'name');
  const selectSchoolyearOpts = useCreateSelectOptions(schoolyears, 'uuid', 'name');
  const selectGradeOpts = useCreateSelectOptions(
    grades.map((g) => {
      return { value: g, label: g };
    }),
    'value',
    'label',
  );
  const selectedSchoolyear = selectSchoolyearOpts.find((opt) => {
    return values.schoolyear ? opt.value === values.schoolyear : false;
  });
  const selectedGrades = selectGradeOpts.filter((opt) => {
    return values.grades?.includes(opt.value as string);
  });

  return (
    <Grid useFormGap>
      <GridRow spacingBottom='s'>
        <GridColumn width={6}>
          <Input
            autoFocus
            label={t('common.designation')}
            name='name'
            required
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            error={errors.name}
          />
        </GridColumn>
        <GridColumn width={6}>
          <Select
            label={t('common.subject')}
            name='subject'
            value={values?.subject}
            error={errors.subject}
            options={selectSubjectsOpts}
            required
            isClearable={false}
            isSearchable
            onChange={(event) => setFieldValue('subject', event)}
            onBlur={handleBlur}
            menuPosition='fixed'
          />
        </GridColumn>
      </GridRow>
      <GridRow spacingTop='s' spacingBottom='s' breakpoint='phone'>
        <GridColumn width={6}>
          <Select
            label={t('common.schoolyear')}
            name='schoolyear'
            options={selectSchoolyearOpts}
            required
            isClearable={false}
            value={selectedSchoolyear}
            error={errors.schoolyear}
            isSearchable
            onChange={(event) => {
              const a: SelectOptionType | null = event as SingleValue<SelectOptionType>;
              setFieldTouched('schoolyear', true);
              setFieldValue('schoolyear', a?.value);
            }}
            onBlur={handleBlur}
            menuPosition='fixed'
          />
        </GridColumn>
        <GridColumn width={6}>
          <Select
            label={t('courses.grade')}
            name='grades'
            isMulti
            value={selectedGrades}
            options={selectGradeOpts}
            isSearchable
            onChange={(e) => {
              setFieldValue(
                'grades',
                (e as MultiValue<SelectOptionType>).map((e) => e.value),
              );
            }}
            menuPosition='fixed'
          />
        </GridColumn>
      </GridRow>
      <GridRow spacingTop='s'>
        <Checkbox
          label={t('group.collaborating')}
          name={'collaborating'}
          disabled={!permissions?.canCreateCollaborationGroups()}
          checked={values.collaborating}
          onChange={(event) => {
            setFieldValue('collaborating', event.target.checked);
          }}
        />
      </GridRow>
    </Grid>
  );
};
