import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationTabs, NavigationTabsType } from '../../components/NavigationTabs/NavigationTabs';
import { LazyLoader } from '@bp/ui-components';
import { Outlet } from 'react-router-dom';
import { BpPage } from '../../components/BpPage/BpPage';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';

export function AdminPage() {
  const { t } = useTranslation();
  const perms = usePermissionChecker();

  const tabs: NavigationTabsType[] = [
    {
      title: t('admin.title'),
      path: '/admin',
      pathMatchEnd: true,
    },
    ...(perms?.isOmniAdmin()
      ? [
          {
            title: t('institution.admin'),
            path: 'admin',
          },
        ]
      : []),
    ...(perms?.isOmniAdmin()
      ? [
          {
            title: t('organizations.title.plural'),
            path: 'organizations',
          },
        ]
      : []),
  ];

  return (
    <BpPage title={t('admin.title')}>
      <NavigationTabs tabs={tabs} className='mb-6' />
      <Suspense fallback={<LazyLoader embedded transparent forceHeight='45vh' />}>{<Outlet />}</Suspense>
    </BpPage>
  );
}
