import { AsyncStorage, INavigator, UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { Capacitor } from '@capacitor/core';
import {
  CHILD_PROFILES_SCOPE,
  OMNI_ORGANIZATION_SCOPE,
  ORGANIZATION_SCOPE,
  OTHER_PROFILES_SCOPE,
  PROFILE_SCOPE,
  ROLES_SCOPE,
  USER_SCOPE,
} from '@bp/pim-auth-constants';

// singleton instance of UserManager
export class BpUserManager {
  private userManager: UserManager | null = null;

  setUserManager(store: AsyncStorage | Storage, navigator?: INavigator) {
    if (!this.userManager) {
      this.userManager = new UserManager(
        {
          stateStore: new WebStorageStateStore({ store: store }),
          automaticSilentRenew: true,
          loadUserInfo: true,
          includeIdTokenInSilentSignout: false,
          includeIdTokenInSilentRenew: false,
          authority: import.meta.env.VITE_APP_AUTH_SERVER_URI,
          client_id: import.meta.env.VITE_APP_AUTH_CLIENT_ID,
          redirect_uri:
            Capacitor.getPlatform() === 'ios'
              ? import.meta.env.VITE_APP_IOS_REDIRECT_URI
              : import.meta.env.VITE_APP_REDIRECT_URI,
          post_logout_redirect_uri: import.meta.env.VITE_APP_LOGOUT_REDIRECT_URI,
          prompt: 'consent',
          scope: [
            'openid',
            'offline_access',
            USER_SCOPE,
            PROFILE_SCOPE,
            ROLES_SCOPE,
            ORGANIZATION_SCOPE,
            OTHER_PROFILES_SCOPE,
            CHILD_PROFILES_SCOPE,
            OMNI_ORGANIZATION_SCOPE,
          ].join(' '),
        },
        navigator,
      );
    }
    return this.userManager;
  }

  getUserManager() {
    if (!this.userManager) {
      throw new Error('UserManager not initialized');
    }
    return this.userManager;
  }
}
