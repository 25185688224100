import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationTabs, NavigationTabsType } from '../../components/NavigationTabs/NavigationTabs';
import { LazyLoader } from '@bp/ui-components';
import { Outlet } from 'react-router-dom';
import { BpPage } from '../../components/BpPage/BpPage';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../hooks/useAuthClaims';

export function SettingsPage() {
  const { t } = useTranslation();
  const perms = usePermissionChecker();
  const organization = useAuthClaims().pimAuthClaims.getOrganizationUuid();

  const tabs: NavigationTabsType[] = [
    {
      title: t('overview.title'),
      path: '/settings',
      pathMatchEnd: true,
    },
    ...(perms?.isOrganizationAdmin({ uuid: organization })
      ? [
          {
            title: t('absenceReason.title'),
            path: 'absence-reasons',
          },
        ]
      : []),
    ...(perms?.canAcceptCollaboration({ uuid: organization }) || perms?.canCreateCollaboration({ uuid: organization })
      ? [
          {
            title: t('collaborations.title'),
            path: 'collaborations',
          },
        ]
      : []),
    ...(perms?.canUpdateCooperationGroups()
      ? [
          {
            title: t('collaborations.groups'),
            path: 'collaboration-groups',
          },
        ]
      : []),
    ...(perms?.canUpdateCooperationGroups()
      ? [
          {
            title: t('collaborations.courses'),
            path: 'collaboration-courses',
          },
        ]
      : []),
    // ...(perms?.isOrganizationAdmin({ uuid: organization })
    //   ? [
    //       {
    //         title: t('institution.dataSources'),
    //         path: 'sources',
    //       },
    //     ]
    //   : []),
  ];

  return (
    <BpPage title={t('settings.title')}>
      <NavigationTabs tabs={tabs} className='mb-6' />
      <Suspense fallback={<LazyLoader embedded transparent forceHeight='45vh' />}>{<Outlet />}</Suspense>
    </BpPage>
  );
}
