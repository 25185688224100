import { GroupsTable, GroupsTableType } from '../../components/GroupsTable/GroupsTable';
import { FC, Suspense, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BpPage } from '../../components/BpPage/BpPage';
import { LazyLoader, Modal } from '@bp/ui-components';
import { GroupType, useBpDeleteGroupMutation, useGroupAsGroupsQuery } from '../../client/bp-graphql-client-defs';
import { GroupForm } from '../../components/GroupForm/GroupForm';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useConfirm } from '../../hooks/useConfirm';
import { OrganizationConfigContext } from '../../context/OrganizationConfigContext';
import { GroupSettingsForm } from 'components/GroupSettingsForm/GroupSettingsForm';
import dayjs from 'dayjs';
import { useGroupOrCoursesOnlineEvents } from 'hooks/useCalendarEvents';
import { useGetMatrixRooms } from 'hooks/matrix/useGetMatrixRooms';

export const GroupsPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pimAuthClaims, updateClaims } = useAuthClaims();

  const { currentSchoolYear } = useContext(OrganizationConfigContext);

  const [claimsTrigger, setClaimsTrigger] = useState<boolean>(false);

  const context = useMemoizedCacheTag('GROUP');

  const [, deleteGroup] = useBpDeleteGroupMutation();

  const [{ data }] = useGroupAsGroupsQuery({
    variables: {
      where: {
        organization: { uuid_IN: pimAuthClaims.getAllowedOrganizationUuids() },
        type: GroupType.Group,
        OR: [
          { editors_SOME: { uuid: pimAuthClaims.getProfile().uuid } },
          { viewers_SOME: { uuid: pimAuthClaims.getProfile().uuid } },
        ],
      },
    },
    context,
  });

  const onlineEvents = useGroupOrCoursesOnlineEvents((data?.groups ?? []).map((c) => c.uuid) ?? []);
  const rooms = useGetMatrixRooms();

  const tableData: GroupsTableType[] = useMemo(() => {
    return (
      data?.groups.map((group) => {
        const notificationRoom = rooms?.find((room) => room.name.includes(group.uuid));
        let newMessages = false;

        if (notificationRoom) {
          newMessages = notificationRoom.getRoomUnreadNotificationCount() > 0;
        }

        return {
          uuid: group.uuid,
          organizationUuid: group.organization.uuid,
          organizationName: group.organization.displayName ?? '',
          name: group.name,
          managedBy:
            group.managedBy !== 'bp'
              ? group.managedBy
              : (group.admins.map((admin) => admin.displayNameShort).join(', ') ?? group.managedBy),
          isCommunity: group.isCommunity,
          schoolyearName: group.schoolYear?.name ?? undefined,
          meetingInProgress:
            onlineEvents.find((v) => v.originUuid === group.uuid)?.virtualLocations.find((v) => v.running)?.running ??
            false,
          admins: group.admins,
          newMessage: newMessages,
          editors: group.editors,
          viewers: group.viewers,
          externalServices: {
            threema: {
              enabled: group.externalServices?.threema?.enabled ?? false,
              lastSync: group.externalServices?.threema?.lastSync
                ? dayjs(group.externalServices?.threema.lastSync)
                : undefined,
            },
            zoom: {
              enabled: group.externalServices?.zoom?.enabled ?? false,
              lastSync: group.externalServices?.zoom?.lastSync
                ? dayjs(group.externalServices?.zoom.lastSync)
                : undefined,
            },
            nextcloud: {
              enabled: group.externalServices?.nextcloud?.enabled ?? false,
              lastSync: group.externalServices?.nextcloud?.lastSync
                ? dayjs(group.externalServices?.nextcloud.lastSync)
                : undefined,
            },
          },
        };
      }) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSchoolYear?.uuid, data?.groups, onlineEvents, rooms]);

  const [modalMode, setModalMode] = useState<{
    open: boolean;
    mode: 'create' | 'edit' | '';
    groupUuid?: string | null;
  }>({ open: false, mode: '', groupUuid: null });

  const { ConfirmationDialog, confirm: confirmDelete } = useConfirm({
    defaultTitle: t('common.delete'),
    defaultConfirmText: t('delete.confirmText'),
  });

  const handleAdd = () => {
    setModalMode({ open: true, mode: 'create' });
  };

  const handleEdit = (uuid: string) => {
    setModalMode({ open: true, mode: 'edit', groupUuid: uuid });
  };

  const handleDelete = async (uuid: string) => {
    const res = await confirmDelete();
    console.log(res);
    if (res) {
      await deleteGroup({ id: uuid }, context);
    }
  };

  const [settingsModalOpen, setSettingsModalOpen] = useState<{
    open: boolean;
    groupUuid: string | null;
  }>({ open: false, groupUuid: null });

  const handleSettings = (groupUuid: string) => {
    setSettingsModalOpen({ open: true, groupUuid: groupUuid });
  };

  const handleSettingsClose = () => {
    setSettingsModalOpen({ open: false, groupUuid: null });
  };

  return (
    <BpPage title={t('groups.myGroups', { year: currentSchoolYear?.name })} breakHeader>
      <GroupsTable
        data={tableData}
        onNavigate={(uuid) => navigate('/groups/' + uuid)}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onEditProfiles={() => {}}
        onDelete={async (uuid) => await handleDelete(uuid)}
        onSettings={(uuid) => handleSettings(uuid)}
      />

      <Modal
        isOpen={modalMode.open}
        onRequestClose={() => setModalMode({ open: false, mode: '', groupUuid: null })}
        title={modalMode.mode === 'edit' ? t('groups.editGroup') : t('groups.addGroup')}
        hideFooter
      >
        <Suspense fallback={<LazyLoader embedded forceHeight='30vh' />}>
          <GroupForm
            groupUuid={modalMode.groupUuid ?? ''}
            onClose={async () => {
              await updateClaims(() => setClaimsTrigger(!claimsTrigger));
              setModalMode({ open: false, mode: '', groupUuid: null });
            }}
          />
        </Suspense>
      </Modal>

      <Modal
        title={t('courses.coursesSettings')}
        isOpen={settingsModalOpen.open}
        shouldCloseOnEsc={true}
        onRequestClose={handleSettingsClose}
      >
        <GroupSettingsForm onClose={handleSettingsClose} groupUuid={settingsModalOpen.groupUuid} />
      </Modal>

      <ConfirmationDialog />
    </BpPage>
  );
};
