import { FC } from 'react';
import styles from './TeachingUnit.module.scss';
import classNames from 'classnames';
import { AddIcon, EditIcon, EmptyState } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import {
  useBpCalendarEventsQuery,
  useBpDeleteAssignmentsMutation,
  useBpDeleteCalendarEventMutation,
  useBpDeleteWorkMaterialsMutation,
} from '../../client/bp-graphql-client-defs';
import { BpCard } from '../BpCard/BpCard';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import {
  AssignmentsPreviewTable,
  AssignmentsPreviewTableType,
} from '../AssignmentsPreviewTable/AssignmentsPreviewTable';
import { AppointmentsPreviewTable } from '../AppointmentsPreviewTable/AppointmentsPreviewTable';
import {
  WorkMaterialsPreviewTable,
  WorkMaterialsPreviewTableType,
} from '../WorkMaterialsPreviewTable/WorkMaterialsPreviewTable';
import { FilePreviewTable, FilePreviewTableType } from '../FilePreviewTable/FilePreviewTable';
import { BpTipTapText } from '../BpTipTapText/BpTipTapText';

export type TeachingUnitType = {
  uuid: string;
  title: string;
  description?: string;
  workMaterials: WorkMaterialsPreviewTableType[];
  assignments: AssignmentsPreviewTableType[];
  note: {
    uuid: string;
    text: string;
    fileEntries: FilePreviewTableType[];
  };
};

export type TeachingUnitProps = {
  onTeachingUnitEdit: (teachingUnitUuid: string) => void;
  onNewAssignment: (teachingUnitUuid: string) => void;
  onNewAppointment: (teachingUnitUuid: string) => void;
  onNewWorkMaterial: (teachingUnitUuid: string) => void;
  onNoteEdit: (teachingUnitUuid: string) => void;
  onEventEdit: (eventUuid: string) => void;
  onWorkMaterialEdit: (workmaterialUuid: string) => void;
  onAssignmentEdit: (assignmentUuid: string) => void;
  onAssignmentNavigate: (assignmentUuid: string) => void;
  onAppointmentNavigate: (appointmentUuid: string) => void;
  onWorkMaterialNavigate: (workMaterialUuid: string) => void;
  className?: string | undefined;
  teachingUnit: TeachingUnitType;
};

export const TeachingUnit: FC<TeachingUnitProps> = ({
  onNewAssignment,
  onNewWorkMaterial,
  onNewAppointment,
  onTeachingUnitEdit,
  onEventEdit,
  onNoteEdit,
  onWorkMaterialEdit,
  onAssignmentEdit,
  onAssignmentNavigate,
  onAppointmentNavigate,
  onWorkMaterialNavigate,
  className,
  teachingUnit,
}) => {
  const { t } = useTranslation();
  const lesson = teachingUnit;

  const contextEvent = useMemoizedCacheTag('EVENT');
  const contextAssignment = useMemoizedCacheTag('ASSIGNMENT');
  const contextWorkMaterial = useMemoizedCacheTag('WORKMATERIAL');

  const [{ data: eventsData }] = useBpCalendarEventsQuery({
    context: contextEvent,
    variables: {
      where: {
        teachingUnit: {
          uuid: teachingUnit.uuid,
        },
      },
    },
  });

  const [, deleteAssignment] = useBpDeleteAssignmentsMutation();
  const [, deleteWorkMaterial] = useBpDeleteWorkMaterialsMutation();
  const [, deleteEvent] = useBpDeleteCalendarEventMutation();

  const classes = classNames(styles['teaching-unit'], className);

  return (
    <div className={classes}>
      {!lesson ? (
        <EmptyState
          forcedHeight='45vh'
          fitParent
          title={t('teachingUnits.noTeachingUnits')}
          subtitle={t('teachingUnits.noTeachingUnitsHint')}
          iconColor='var(--color-primary-light)'
        />
      ) : (
        <div className={styles.wrapper}>
          <BpCard
            preventCollapsible
            className={styles.unit}
            hideContent={!lesson.description}
            header={{
              headline: lesson.title,
              actions: [
                {
                  text: t('common.edit'),
                  callback: () => onTeachingUnitEdit(teachingUnit.uuid),
                  icon: <EditIcon data-testid={'editTu'} className='svg-icon small' />,
                },
              ],
            }}
          >
            <BpTipTapText embedded content={lesson.description ?? ''} />
          </BpCard>
          <div className={classNames(styles.columns, styles.top)}>
            <BpCard
              noPadding
              noMargin
              header={{
                headline: t('assignments.title'),
                actions: [
                  {
                    text: t('common.add'),
                    callback: () => onNewAssignment(teachingUnit.uuid),
                    icon: <AddIcon className='svg-icon small' />,
                  },
                ],
              }}
            >
              <AssignmentsPreviewTable
                data={
                  lesson.assignments.sort((a, b) => {
                    return new Date(b.dueDate).valueOf() - new Date(a.dueDate).valueOf();
                  }) ?? []
                }
                onNavigate={(uuid) => onAssignmentNavigate(uuid)}
                onEdit={(uuid) => onAssignmentEdit(uuid)}
                onDelete={(uuid) => deleteAssignment({ where: { uuid: uuid } }, contextAssignment)}
              />
            </BpCard>
            <BpCard
              noPadding
              noMargin
              header={{
                headline: t('appointments.title'),
                subHeadline: eventsData?.calendarEvents.length,
                actions: [
                  {
                    text: t('common.add'),
                    callback: () => onNewAppointment(teachingUnit.uuid),
                    icon: <AddIcon className='svg-icon small' />,
                  },
                ],
              }}
            >
              <AppointmentsPreviewTable
                data={
                  eventsData?.calendarEvents.map((event) => {
                    return {
                      uuid: event.uuid,
                      name: event.title ?? '',
                      date: event.start,
                    };
                  }) ?? []
                }
                onNavigate={(uuid) => onAppointmentNavigate(uuid)}
                onEdit={(uuid) => onEventEdit(uuid)}
                onDelete={(uuid) => deleteEvent({ where: { uuid: uuid } }, contextEvent)}
              />
            </BpCard>
          </div>
          <div className={classNames(styles.columns, styles.bottom)}>
            <BpCard
              noPadding
              noMargin
              header={{
                headline: t('workmaterials.title'),
                subHeadline: lesson.workMaterials?.length,
                actions: [
                  {
                    text: t('common.add'),
                    callback: () => onNewWorkMaterial(teachingUnit.uuid),
                    icon: <AddIcon className='svg-icon small' />,
                  },
                ],
              }}
            >
              <WorkMaterialsPreviewTable
                data={lesson.workMaterials ?? []}
                onNavigate={(uuid) => onWorkMaterialNavigate(uuid)}
                onEdit={(uuid) => onWorkMaterialEdit(uuid)}
                onDelete={(uuid) => deleteWorkMaterial({ where: { uuid: uuid } }, contextWorkMaterial)}
              />
            </BpCard>
            <BpCard
              noPadding
              noMargin
              className={styles.notes}
              header={{
                headline: t('notes.titleSingular'),
                actions: [
                  {
                    text: t('common.edit'),
                    callback: () => onNoteEdit(lesson?.note?.uuid ?? ''),
                    icon: <EditIcon className='svg-icon small' />,
                  },
                ],
              }}
            >
              {!lesson.note || !lesson.note.text || !lesson.note.fileEntries ? (
                <EmptyState size='small' hideIcon subtitle={t('notes.noNotes')} fitParent forcedHeight='150px' />
              ) : (
                <>
                  {lesson.note.text && (
                    <div className={classNames(styles.note, 'has-scrollbar')}>
                      <BpTipTapText content={lesson.note.text} customPadding='0 var(--spacing-6)' />
                    </div>
                  )}
                  {lesson.note.fileEntries && (
                    <FilePreviewTable
                      className={styles.files}
                      hasNote={!!lesson.note.text}
                      data={lesson.note.fileEntries ?? []}
                    />
                  )}
                </>
              )}
            </BpCard>
          </div>
        </div>
      )}
    </div>
  );
};
