import { useTranslation } from 'react-i18next';
import { Skeleton } from '@bp/ui-components';
import { BpPage } from 'components/BpPage/BpPage';

export function MediaLibraryPage() {
  const { t } = useTranslation();

  return (
    <BpPage title={t('mediaLibrary.title')}>
      <Skeleton />
    </BpPage>
  );
}
