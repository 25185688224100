import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { ArrowUpIcon } from '@bp/ui-components';
import classNames from 'classnames';
import styles from './BpCard.module.scss';
import { BpHeader } from '../../components/BpHeader/BpHeader';
import { useContainerDimensions, useWindowDimensions } from 'utils/dimensions';

export interface BpActionButton {
  text?: string;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  disabled?: boolean;
  hierarchy?: 'primary' | 'secondary' | 'tertiary' | 'tertiary-not-on-white' | 'ghost' | undefined;
  isSubmit?: boolean;
  callback?: (event: React.MouseEvent<Element>) => void;
  className?: string | undefined;
}

type BpCardProps = {
  children: ReactNode;
  maxHeight?: string;
  minHeight?: string;
  fixedHeight?: string;
  fitParent?: boolean;
  header?: BpCardHeader;
  noPadding?: boolean;
  noMargin?: boolean;
  canScroll?: boolean;
  showOverflow?: boolean;
  alwaysCollapsible?: boolean;
  preventCollapsible?: boolean;
  disableCollapsible?: boolean;
  isCollapsed?: boolean;
  isBig?: boolean;
  hideContent?: boolean;
  hideBackground?: boolean;
  hideActionTextBreakpoint?: 'tiny' | 'small' | 'medium';
  showBorder?: boolean;
  isEmbedded?: boolean;
  bigActionsGap?: boolean;
  onCollapse?: () => void;
  className?: string | undefined;
};

type BpCardHeader = {
  isVisible?: boolean;
  headline: string;
  subHeadline?: string | number;
  showSubHeadlineAsChip?: boolean;
  actions?: BpActionButton[];
  showDivider?: boolean;
};

export const BpCard: FC<BpCardProps> = ({
  children,
  maxHeight,
  fixedHeight,
  minHeight,
  fitParent = false,
  header,
  noPadding = false,
  noMargin = true,
  canScroll = false,
  showOverflow = false,
  alwaysCollapsible = false,
  preventCollapsible = false,
  disableCollapsible = false,
  isCollapsed = false,
  isBig = false,
  hideContent = false,
  hideBackground = false,
  hideActionTextBreakpoint = 'tiny',
  showBorder = false,
  isEmbedded = false,
  bigActionsGap = false,
  onCollapse,
  className,
}) => {
  const [_isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const { isPhone, isTablet } = useWindowDimensions();
  const isMobile = isPhone || isTablet;

  const ref = useRef<HTMLDivElement>(null);
  const { isTiny, isSmall, isMedium } = useContainerDimensions(ref);

  useEffect(() => {
    setIsCollapsed(isCollapsed);
  }, [isCollapsed]);

  const classes = classNames(
    styles['bp-card'],
    {
      [styles['fit-parent']]: fitParent,
      [styles['no-header']]: !header,
      [styles['no-padding']]: noPadding || isEmbedded,
      [styles['no-margin']]: noMargin,
      [styles['has-scroll']]: canScroll,
      [styles['has-overflow']]: showOverflow,
      [styles['is-collapsible']]: alwaysCollapsible || (!preventCollapsible && isMobile) || _isCollapsed,
      [styles['is-collapsed']]: _isCollapsed,
      [styles['big']]: isBig || isEmbedded,
      [styles['no-background']]: hideBackground || isEmbedded,
      [styles['with-border']]: showBorder,
      [styles['embedded']]: isEmbedded,
    },
    className,
  );

  const headerClasses = classNames(styles.header, {
    [styles['with-divider']]: header?.showDivider,
  });

  const contentClasses = classNames(styles.content, {
    'has-scrollbar': canScroll,
  });

  return (
    <div
      className={classes}
      style={{
        height: fixedHeight && !_isCollapsed ? fixedHeight : undefined,
        maxHeight: maxHeight ?? undefined,
        minHeight: minHeight ?? undefined,
      }}
      ref={ref}
    >
      {header && (
        <div className={styles['header-wrapper']}>
          <div className={headerClasses}>
            <BpHeader
              headline={header.headline}
              subHeadline={header.subHeadline}
              size='s'
              noMargin
              bigActionsGap={bigActionsGap}
              showSubHeadlineAsChip={header.showSubHeadlineAsChip}
              actions={[
                ...(header.actions
                  ? (header.actions.map((action) => {
                      return {
                        text:
                          (hideActionTextBreakpoint === 'tiny' && isTiny) ||
                          (hideActionTextBreakpoint === 'small' && (isTiny || isSmall)) ||
                          (hideActionTextBreakpoint === 'medium' && (isTiny || isSmall || isMedium))
                            ? undefined
                            : action.text,
                        callback: action.callback,
                        icon: action.icon ?? undefined,
                        hierarchy: action.hierarchy ?? 'ghost',
                        disabled: action.disabled ?? false,
                        className: action.className ?? undefined,
                      };
                    }) ?? [])
                  : []),
                ...(alwaysCollapsible || (!preventCollapsible && isMobile) || _isCollapsed
                  ? [
                      {
                        callback: () => {
                          setIsCollapsed(!_isCollapsed);
                          if (onCollapse) onCollapse();
                        },
                        icon: (
                          <ArrowUpIcon
                            style={{
                              transform: _isCollapsed ? 'rotate(-180deg)' : undefined,
                            }}
                            className='svg-icon'
                          />
                        ),
                        disabled: disableCollapsible,
                      },
                    ]
                  : []),
              ]}
            />
          </div>
        </div>
      )}
      {!hideContent && (
        <div className={contentClasses} style={{ maxHeight: maxHeight ?? undefined }}>
          {children}
        </div>
      )}
    </div>
  );
};
