import { useDefaultSorting, Table } from '@bp/ui-components';
import { Row } from '@tanstack/react-table';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { niceDate } from '../../utils/dateCalculations';
import { handleFileEntryDownload } from '../../utils/download';
import { BpLink } from '../../components/BpLink/BpLink';

export type FilePreviewTableType = {
  uuid: string;
  name: string;
  uploaded: string;
};

type FilePreviewTableProps = {
  data: FilePreviewTableType[];
  hasNote?: boolean;
  className?: string | undefined;
};

export const FilePreviewTable: FC<FilePreviewTableProps> = ({ data, hasNote, className }) => {
  const { t } = useTranslation();
  const { sorting, onSortingChange } = useDefaultSorting([{ id: 'name', desc: false }]);

  return (
    <Table<FilePreviewTableType>
      maxHeight={hasNote ? 105 : 150}
      canScroll
      className={className}
      hideHeader
      showSort
      sorting={sorting}
      onSortingChange={onSortingChange}
      customPadding='var(--spacing-6)'
      customRowHeight='var(--list-row-height)'
      customLastColSpacing='var(--spacing-3)'
      lastColWidth='100px'
      breakpoint={null}
      data={data}
      columns={[
        {
          id: 'name',
          accessorKey: 'name',
          size: 300,
          cell: ({ row }) => {
            return (
              <BpLink
                value={row.original.name}
                onNavigate={() => {
                  handleFileEntryDownload(row.original.uuid);
                }}
              />
            );
          },
        },
      ]}
      emptyStateSettings={{
        hideIcon: true,
        size: 'small',
        title: '',
        subtitle: t('files.noFiles'),
        forcedHeight: hasNote ? '60px' : '150px',
        padding: hasNote ? 'none' : 'xl',
        fitParent: true,
      }}
      lastCol={(row: Row<FilePreviewTableType>) => {
        return <>{niceDate(row.original.uploaded, 'short')}</>;
      }}
    />
  );
};
