import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Checkbox, Grid, GridColumn, GridRow, Input, Select, SelectOptionType } from '@bp/ui-components';
import { useCreateSelectOptions } from '../../hooks/useCreateSelectOptions';
import { SingleValue } from 'react-select';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { GroupFormValues } from './GroupForm';
import { useSchoolYearsQuery } from '../../client/bp-graphql-client-defs';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';

export const GroupFormBaseData = () => {
  const { t } = useTranslation();
  const { handleChange, setFieldValue, values, errors, touched, setFieldTouched } = useFormikContext<GroupFormValues>();
  const { pimAuthClaims } = useAuthClaims();
  const permissions = usePermissionChecker();
  const context = useMemoizedCacheTag('GROUP');

  const [{ data: schoolyearsData }] = useSchoolYearsQuery({
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
    },
    context,
  });

  const schoolyears = schoolyearsData?.schoolYears;

  const selectSchoolyearOpts = useCreateSelectOptions(schoolyears, 'uuid', 'name');

  const selectedSchoolyear = selectSchoolyearOpts.find((opt) => {
    return values.schoolyear ? opt.value === values.schoolyear : false;
  }) ?? { label: t('group.schoolyearSpanning'), value: 'schoolyearSpanning' };

  return (
    <Grid useFormGap>
      <GridRow spacingBottom='s'>
        <GridColumn width={6}>
          <Input
            autoFocus
            label={t('common.designation')}
            name={'name'}
            required
            onChange={handleChange}
            value={values.name}
            error={errors.name}
          />
        </GridColumn>
        <GridColumn width={6}>
          <Select
            label={t('common.schoolyear')}
            name={'schoolyear'}
            options={selectSchoolyearOpts}
            value={selectedSchoolyear}
            isClearable={selectSchoolyearOpts.some((opt) =>
              values.schoolyear ? opt.value === values.schoolyear : false,
            )}
            onChange={(event) => {
              const schoolyear = event as SingleValue<SelectOptionType>;
              setFieldTouched('schoolyear', true);
              setFieldValue('schoolyear', schoolyear?.value ?? null);
            }}
            menuPosition='fixed'
          />
        </GridColumn>
      </GridRow>
      <GridRow spacingTop='s' spacingBottom='s'>
        <Input
          label={t('common.description')}
          name={'description'}
          onChange={handleChange}
          value={values.description}
          {...(errors.description &&
            touched.description && {
              error: errors.description,
            })}
        />
      </GridRow>
      <GridRow spacingTop='s'>
        <Checkbox
          label={t('group.collaborating')}
          name={'collaborating'}
          disabled={!permissions?.canCreateCollaborationGroups()}
          checked={values.collaborating}
          onChange={(event) => {
            setFieldValue('collaborating', event.target.checked);
          }}
        />
      </GridRow>
    </Grid>
  );
};
