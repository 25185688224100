import { Form, Formik } from 'formik';
import { FC } from 'react';
import { CombinedError } from 'urql';
import { showErrorToast } from '../../utils/showErrorToast';
import { showSuccessToast } from '../../utils/showSuccessToast';
import {
  useBpCreateWorkMaterialsMutation,
  useBpUpdateWorkMaterialsMutation,
  useBpWorkMaterialsQuery,
} from '../../client/bp-graphql-client-defs';
import { useTranslation } from 'react-i18next';
import { Button, InplaceEdit, LazyLoader } from '@bp/ui-components';
import { BpCard } from '../BpCard/BpCard';
import { FileEntriesType } from '../AssignmentForm/AssignmentForm';
import { FileUpload } from '../FileUpload/FileUpload';
import styles from './WorkMaterialsForm.module.scss';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { FileTable } from '../FileTable/FileTable';
import { schema } from './validation/schema';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useParams } from 'react-router-dom';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { BpTipTap } from '../BpTipTap/BpTipTap';
import { useDays } from '../../hooks/useDays';
import { connectByUuid } from '../../utils/connectLib';
import { useWindowDimensions } from 'utils/dimensions';

type GroupWorkMaterialsFormProps = {
  canArchive: boolean;
  onClose: (uuid?: string, title?: string) => void;
  workMaterialUuid?: string;
  teachingUnitUuid: string;
};

type WorkMaterialValuesType = {
  title: string;
  description: string;
  visibleFrom: string;
  visibleTo: string;
  publicFromDate: string;
  visibleAfterVisibleTo: boolean;
  fileEntries: FileEntriesType;
  showPublicationDate: boolean;
  teachingUnitUuid: string;
};

export const GroupWorkMaterialsForm: FC<GroupWorkMaterialsFormProps> = ({
  canArchive,
  onClose,
  workMaterialUuid,
  teachingUnitUuid,
}) => {
  const { t } = useTranslation();
  const { groupUuid } = useParams();
  const context = useMemoizedCacheTag('WORKMATERIAL');
  const perms = usePermissionChecker();
  const organizationUuid = useAuthClaims().pimAuthClaims.getOrganizationUuid();
  const profileUuid = useAuthClaims().pimAuthClaims.getProfile().uuid;

  const { isPhone } = useWindowDimensions();

  const [, bpCreateWorkMaterial] = useBpCreateWorkMaterialsMutation();
  const [, bpUpdateWorkMaterial] = useBpUpdateWorkMaterialsMutation();

  const [workmaterial] = useBpWorkMaterialsQuery({
    context,
    variables: {
      where: {
        uuid: workMaterialUuid,
      },
    },
  });

  const workMaterialToEdit = workmaterial.data?.workMaterials.find((mat) => mat.uuid === workMaterialUuid);

  const { ensureDay } = useDays();

  const handleSubmit = async (values: WorkMaterialValuesType) => {
    let err: CombinedError | undefined;
    const { description, title, visibleTo, visibleFrom, publicFromDate, visibleAfterVisibleTo } = values;

    const visibleFromDay = await ensureDay(new Date(visibleFrom));
    const visibleToDay = await ensureDay(new Date(visibleTo));
    const publicFrom = await ensureDay(new Date(publicFromDate));

    const fileEntriesConnect = () =>
      values.fileEntries && values.fileEntries.length > 0
        ? {
            connect: values.fileEntries?.map((file, index) => {
              return {
                where: { node: { uuid: file.uuid } },
                edge: { order: index },
              };
            }),
          }
        : {};
    if (
      workMaterialToEdit &&
      perms?.canUpdateWorkmaterial(
        {
          uuid: groupUuid ?? '',
          organization: { uuid: organizationUuid },
        },
        {
          owner: { uuid: workMaterialToEdit?.holder?.owner.uuid, organization: { uuid: organizationUuid } },
          workmaterialUuid: organizationUuid,
        },
      )
    ) {
      const { error } = await bpUpdateWorkMaterial(
        {
          where: { uuid: workMaterialUuid },
          update: {
            material: {
              delete: {},
              create: {
                node: {
                  text: description,
                  textMediaType: 'text/plain',
                  fileEntries: fileEntriesConnect(),
                },
              },
            },
            publicFrom: connectByUuid(publicFrom?.uuid),
            title: title,
            visibleAfterVisibleTo: visibleAfterVisibleTo,
            visibleFrom: connectByUuid(visibleFromDay?.uuid),
            visibleTo: connectByUuid(visibleToDay?.uuid),
            holder: {
              TeachingUnit: {
                disconnect: {},
                connect: {
                  where: {
                    node: {
                      uuid: teachingUnitUuid,
                    },
                  },
                },
              },
            },
          },
        },
        context,
      );

      err = error;
    } else {
      if (
        perms?.canCreateWorkmaterial({
          uuid: groupUuid ?? '',
          organization: { uuid: organizationUuid },
        })
      ) {
        const { error } = await bpCreateWorkMaterial(
          {
            input: {
              title: title,
              visibleFrom: connectByUuid(visibleFromDay?.uuid),
              visibleTo: connectByUuid(visibleToDay?.uuid),
              visibleAfterVisibleTo: visibleAfterVisibleTo,
              publicFrom: connectByUuid(publicFrom?.uuid),
              owner: { connect: { where: { node: { uuid: profileUuid } } } },

              material: {
                create: {
                  node: {
                    text: description,
                    textMediaType: 'text/plain',
                    fileEntries: fileEntriesConnect(),
                  },
                },
              },
              holder: {
                TeachingUnit: {
                  connect: {
                    where: {
                      node: {
                        uuid: teachingUnitUuid,
                      },
                    },
                  },
                },
              },
            },
          },
          context,
        );

        err = error;
      }
    }

    if (err) showErrorToast(err);
    if (!err) showSuccessToast(t('common.saved'));
    onClose(workMaterialUuid);
  };

  const initialValues: WorkMaterialValuesType = {
    title: workMaterialToEdit?.title ?? '',
    description: workMaterialToEdit?.material.text ?? '',
    visibleFrom: workMaterialToEdit?.visibleFrom?.date ?? new Date().toISOString(),
    visibleTo: workMaterialToEdit?.visibleTo?.date ?? new Date().toISOString(),
    publicFromDate: workMaterialToEdit?.publicFrom?.date ?? new Date().toISOString(),
    visibleAfterVisibleTo: workMaterialToEdit?.visibleAfterVisibleTo ?? false,
    showPublicationDate: !!workMaterialToEdit?.publicFrom?.date,
    fileEntries: workMaterialToEdit?.material.fileEntries ?? [],
    teachingUnitUuid: teachingUnitUuid,
  };

  return (
    <div className={styles['group-workmaterials-form']}>
      <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={schema}>
        {({ resetForm, setFieldValue, setFieldTouched, values, isSubmitting, errors }) => {
          return isSubmitting ? (
            <LazyLoader transparent forceHeight='50vh' />
          ) : (
            <Form className='bp__form'>
              <div className={'bp__form-header'}>
                <InplaceEdit
                  className={styles.title}
                  name='title'
                  fontSize={isPhone ? 'xl' : 'xxxl'}
                  onBlur={async (value) => {
                    await setFieldValue('title', value);
                    await setFieldTouched('title', true);
                  }}
                  onChange={async (value) => {
                    await setFieldValue('title', value);
                    await setFieldTouched('title', true);
                  }}
                  value={values.title}
                  placeholder={t('common.addTitle') as string}
                  error={errors.title}
                />
                <div className={'bp__form-buttons'}>
                  <Button hierarchy='tertiary' disabled={!canArchive}>
                    {t('common.archive')}
                  </Button>
                  <Button
                    hierarchy='secondary'
                    onClick={() => {
                      resetForm();
                      onClose();
                    }}
                  >
                    {workMaterialUuid ? t('common.discardChanges') : t('common.cancel')}
                  </Button>
                  <Button hierarchy='primary' type={'submit'} disabled={!!errors.title}>
                    {t('common.save')}
                  </Button>
                </div>
              </div>

              <BpCard header={{ headline: t('common.content') }}>
                <BpTipTap
                  className={styles.description}
                  defaultValue={values.description}
                  onChange={async (value) => {
                    await setFieldValue('description', value);
                  }}
                  name={'description'}
                  required={false}
                  placeholder={t('common.addType', { type: t('common.description') })}
                  error={errors.description}
                />
                {values.fileEntries && values.fileEntries.length > 0 && (
                  <FileTable
                    className={styles.list}
                    mode={'edit'}
                    files={values.fileEntries}
                    onRenamed={async (uuid, newName) => {
                      const updated = values.fileEntries.map((fileEntry) => {
                        if (fileEntry.uuid === uuid) {
                          return { ...fileEntry, filename: newName };
                        }
                        return fileEntry;
                      });
                      await setFieldValue('fileEntries', updated);
                    }}
                    onDeleted={async (uuid) => {
                      await setFieldValue(
                        'fileEntries',
                        values.fileEntries.filter((fileEntry) => {
                          return fileEntry.uuid !== uuid;
                        }),
                      );
                    }}
                  />
                )}
                <FileUpload
                  onFileUpload={async (file) => {
                    await setFieldValue('fileEntries', [...values.fileEntries, file]);
                  }}
                />
              </BpCard>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
