import { BpPage } from 'components/BpPage/BpPage';
import { Skeleton } from '@bp/ui-components';

export function PlannedMeetingsPage() {
  return (
    <BpPage title=''>
      <Skeleton />
    </BpPage>
  );
}
