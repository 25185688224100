import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { PageLayoutPlaceholder } from '../../components/PageLayoutPlaceholder/PageLayoutPlaceholder';
import { AboutModal } from '../../components/Modals/AboutModal/AboutModal';
import { useParams } from 'react-router-dom';
import { backendApi } from '../../utils/backendApi';
import { CalendarEvent } from '@bp/bp-graphql-types';
import { Button, EmptyState, Input, NoTasksIcon } from '@bp/ui-components';
import { niceDate } from '../../utils/dateCalculations';
import { isProfileClaim, PROFILE_CLAIM } from '@bp/pim-auth-constants';
import { showErrorToast } from '../../utils/showErrorToast';
import styles from './JoinPage.module.scss';

export const JoinPage: FC = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  const [hasTriedSigning, setHasTriedSigning] = useState(false);
  const [forceSignIn, setForceSignIn] = useState(false);
  const [requestedEvent, setRequestedEvent] = useState<CalendarEvent | null>(null);
  const [joinDisplayName, setJoinDisplayName] = useState<string>('');
  const [joinEmail, setJoinEmail] = useState<string>('');
  const [joinProfileUuid, setJoinProfileUuid] = useState<string>('');

  // try to sign in silently - or fetch public available event
  useEffect(() => {
    if (auth.isAuthenticated) {
      setHasTriedSigning(true);
    }
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSigning) {
      auth.signinSilent().finally(() => setHasTriedSigning(true));
    }
  }, [auth, hasTriedSigning]);

  const profile = useMemo(() => {
    const profile = auth.user?.profile[PROFILE_CLAIM];
    if (!profile || !isProfileClaim(profile)) {
      return undefined;
    }
    return profile;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, hasTriedSigning]);

  const [showAbout, setShowAbout] = useState<boolean>(false);

  const { conference } = useParams();

  useEffect(() => {
    if (hasTriedSigning) {
      backendApi
        .findConference(conference ?? '')
        .then((response) => {
          if (response.ok) {
            response.json().then((data) => setRequestedEvent(data));
            setJoinEmail(profile?.email ?? '');
            setJoinDisplayName(profile?.displayName ?? '');
            setJoinProfileUuid(profile?.uuid ?? '');
          }
        })
        .finally(() => {
          setRequestedEvent(null);
          setForceSignIn(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conference, hasTriedSigning]);

  useEffect(() => {
    if (forceSignIn && !auth.isAuthenticated) {
      void auth.signinRedirect();
    }
  }, [forceSignIn, auth]);

  const login = async () => {};

  const join = async () => {
    if (requestedEvent && conference) {
      const joinUrl = await backendApi.joinConference(conference, {
        displayName: joinDisplayName,
        email: joinEmail,
        profileUuid: joinProfileUuid,
        moderator: true,
      });

      const link = document.createElement('a');
      link.href = await joinUrl.text();
      link.target = '_self';
      link.click();
    } else {
      showErrorToast(t('common.error'));
    }
  };

  return (
    <PageLayoutPlaceholder>
      {!requestedEvent ? (
        <EmptyState maxWidth='200px' title={t('meetings.noMeeting')} icon={<NoTasksIcon />} />
      ) : (
        <div className={styles['join-page']}>
          <div className={styles.header}>
            <div className={styles.title}>{requestedEvent?.title ?? t('common.notFound')}</div>
            <div className={styles.start}>{niceDate(requestedEvent?.start)}</div>
          </div>
          <Input
            name={'joinName'}
            label={t('common.name')}
            onChange={(e) => setJoinDisplayName(e.target.value)}
            value={joinDisplayName}
          />
          {!profile?.email && (
            <Input
              name={'joinEmail'}
              label={t('common.email')}
              onChange={(e) => setJoinEmail(e.target.value)}
              value={joinEmail}
            />
          )}
          {!auth.isAuthenticated && (
            <Button hierarchy='primary' onClick={() => login()} fullWidth className={styles.login}>
              {t('auth.login')}
            </Button>
          )}
          <Button hierarchy='primary' onClick={() => join()} fullWidth className={styles.join}>
            {t('meetings.join')}
          </Button>
        </div>
      )}

      <AboutModal isAdmin={false} state={showAbout} setState={(value) => setShowAbout(value)} />
    </PageLayoutPlaceholder>
  );
};
