import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CloseIcon, Modal } from '@bp/ui-components';

import { InstantMeetings } from './InstantMeetingModal/InstantMeetings';

export function InstantMeetingModal({
  showModal,
  setInstantMeetingModalOpen,
}: {
  showModal: boolean;
  setInstantMeetingModalOpen: (state: boolean) => void;
}) {
  const { t } = useTranslation();

  const handleClose = () => {
    setInstantMeetingModalOpen(false);
  };

  return (
    <Modal
      style={{
        content: {
          maxWidth: ' 500px',
        },
      }}
      footer={
        <div>
          <Button onClick={handleClose} hierarchy='secondary' icon={<CloseIcon />}>
            {t('common.cancel')}
          </Button>
        </div>
      }
      onRequestClose={handleClose}
      shouldCloseOnEsc
      isOpen={showModal}
      title={t('meetings.instantMeeting')}
    >
      <InstantMeetings handleClose={handleClose} />
    </Modal>
  );
}
