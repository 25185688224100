import { WorkMaterial } from '@bp/bp-graphql-types';
import { useTranslation } from 'react-i18next';
import { FC, useMemo } from 'react';
import {
  Button,
  DotsVerticalIcon,
  Dropdown,
  DropdownMenu,
  Row,
  Table,
  TableColumns,
  useDefaultSorting,
} from '@bp/ui-components';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useParams } from 'react-router-dom';
import { ColumnType } from '../../utils/type-guards';
import { BpLink } from 'components/BpLink/BpLink';

type CourseWorkMaterialsTableProps = {
  data: WorkMaterial[];
  isGroupEditor: boolean;
  onClick?: (uuid: string) => void;
  onEdit?: (uuid: string) => void;
  emptyStateTitle?: string;
  emptyStateSubstitle?: string;
  onDelete: (uuid: string, title: string) => void;
};

export const CourseWorkMaterialsTable: FC<CourseWorkMaterialsTableProps> = ({
  data,
  onDelete,
  isGroupEditor,
  onClick,
  onEdit,
  emptyStateTitle,
  emptyStateSubstitle,
}) => {
  const { t } = useTranslation();
  const { courseUuid } = useParams<{
    courseUuid: string;
  }>();

  const { sorting, onSortingChange } = useDefaultSorting([{ id: 'title', desc: false }]);

  const perms = usePermissionChecker();

  const memoizedData = useMemo((): WorkMaterial[] => {
    return data;
  }, [data]);

  const memoizedColumns = useMemo((): TableColumns<WorkMaterial>[] => {
    return [
      {
        id: 'title',
        size: 400,
        canExpand: true,
        header: t('common.name'),
        accessorKey: 'title',
        meta: {
          filterName: 'Name',
        },
        cell: ({ row }: { row: Row<WorkMaterial> }) => {
          return (
            <BpLink value={row.original.title} onNavigate={onClick ? () => onClick(row.original.uuid) : undefined} />
          );
        },
      },
      ...(isGroupEditor
        ? [
            {
              id: 'teachingunit',
              size: 250,
              header: t('teachingUnits.titleSingular'),
              accessorKey: 'holder.title',
              meta: {
                filterName: t('teachingUnits.titleSingular') as string,
              },
            },
          ]
        : []),
      {
        id: 'files',
        accessorKey: 'fileEntries',
        size: 120,
        cell: (cell) => {
          return <>{cell.row.original.material.fileEntries.length}</>;
        },
        header: t('common.documents'),
        meta: {
          filterName: t('common.documents') as string,
        },
      },

      {
        id: 'timeFrame',
        accessorKey: 'timeFrame',
        header: t('common.relevantTimeFrame'),
        size: 200,
        meta: {
          filterName: t('common.relevantTimeFrame') as string,
        },
        enableHiding: true,
      },

      ...(isGroupEditor
        ? [
            {
              id: 'visibleAfterVisibleTo',
              accessorKey: 'visibleAfterVisibleTo',
              size: 150,
              header: t('common.visibleAfter'),
              type: 'boolean' as ColumnType<WorkMaterial>,
              meta: {
                filterName: t('common.visibleAfterVisibleTo') as string,
              },
            },
          ]
        : []),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGroupEditor, onClick]);

  return (
    <>
      <Table<WorkMaterial>
        showSort
        sorting={sorting}
        hideHeader={memoizedData.length === 0}
        onSortingChange={onSortingChange}
        columns={memoizedColumns}
        data={memoizedData}
        breakpoint='580px'
        customPadding='var(--spacing-6)'
        emptyStateSettings={{
          title: emptyStateTitle ?? t('workmaterials.noWorkmaterials'),
          subtitle: emptyStateSubstitle ?? t('workmaterials.createHintCourse'),
          hideIcon: true,
          padding: 'xl',
        }}
        customLastColSpacing='var(--spacing-4)'
        lastCol={(row) => {
          return perms?.canUpdateWorkmaterial(
            {
              uuid: courseUuid ?? '',
              organization: { uuid: data[0].holder.organization?.uuid },
            },
            {
              owner: { uuid: data[0].owner?.uuid, organization: { uuid: data[0].holder.organization?.uuid } },
              workmaterialUuid: data[0].uuid,
            },
          ) ? (
            <Dropdown noPadding trigger={<Button hierarchy='ghost' icon={<DotsVerticalIcon className='svg-icon' />} />}>
              <DropdownMenu
                data-side='left'
                data={[
                  {
                    onClick: () => {
                      if (onEdit) {
                        onEdit(row.original.uuid);
                      }
                    },
                    label: t('common.edit'),
                  },

                  {
                    type: 'ruler',
                    label: '',
                  },

                  {
                    type: 'error',
                    label: t('common.delete'),
                    onClick: async () => {
                      onDelete(row.original.uuid, row.original.title);
                    },
                  },
                ]}
              />
            </Dropdown>
          ) : undefined;
        }}
      />
    </>
  );
};
