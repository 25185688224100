import { CoursesIcon, Table, useDefaultSorting } from '@bp/ui-components';
import { Row } from '@tanstack/react-table';
import { BpLink } from '../../components/BpLink/BpLink';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { SubmissionStatus, useBpAssignmentsCountQuery } from '../../client/bp-graphql-client-defs';

export type CoursesPreviewTableType = {
  uuid: string;
  name: string;
  meetingInProgress: boolean;
  count?: number;
  newMaterial: boolean;
};

type CoursesPreviewTableProps = {
  data: CoursesPreviewTableType[];
  onNavigate: (uuid: string) => void;
};

export const CoursesPreviewTable: FC<CoursesPreviewTableProps> = ({ data, onNavigate }) => {
  const { t } = useTranslation();
  const { sorting, onSortingChange } = useDefaultSorting();

  const context = useMemoizedCacheTag('SUBMISSION');
  const [assignments] = useBpAssignmentsCountQuery({
    context: context,
    variables: {
      where: { uuid_IN: data.map((c) => c.uuid) },
      submissionWhere: {
        status: SubmissionStatus.New,
      },
    },
  });

  const dataWithSubmissionCount: CoursesPreviewTableType[] = data.map((course) => {
    const courseWithNewSubmissions = assignments.data?.groups
      .map((a) => {
        const todoCount = a.teachingUnitsConnection.edges.flatMap((e) => {
          const newSubmsns = e.node.assignments.map((a) => a.submissions.length);
          return newSubmsns.reduce((a, b) => a + b, 0);
        });
        return { courseUuid: a.uuid, count: todoCount.reduce((a, b) => a + b, 0) };
      })
      .find((g) => g.courseUuid === course.uuid);
    return { ...course, count: courseWithNewSubmissions?.count ?? 0 };
  });

  return (
    <Table<CoursesPreviewTableType>
      hideHeader
      showSort
      sorting={sorting}
      onSortingChange={onSortingChange}
      customPadding='var(--spacing-6)'
      customRowHeight='var(--list-row-height)'
      customLastColSpacing='0'
      breakpoint={null}
      data={dataWithSubmissionCount}
      columns={[
        {
          id: 'name',
          accessorKey: 'name',
          cell: ({ row }: { row: Row<CoursesPreviewTableType> }) => (
            <BpLink
              value={row.original.name}
              onNavigate={() => onNavigate(row.original.uuid)}
              inProgress={row.original.meetingInProgress}
              inProgressHint={t('groups.activeMeetingHint')}
              counter={row.original.count}
              counterHint={t('groups.newSubmissionsHint')}
              hasNews={row.original.newMaterial}
              hasNewsHint={t('groups.newMessageHint')}
            />
          ),
          canExpand: true,
        },
      ]}
      emptyStateSettings={{
        icon: <CoursesIcon className='svg-icon primary-light' />,
        size: 'small',
        title: '',
        subtitle: t('courses.noCourses'),
        padding: 'xl',
      }}
    />
  );
};
