import { urqlClient } from './urqlClient';
import {
  BpFileEntriesDocument,
  BpFileEntriesQuery,
  BpFileEntriesQueryVariables,
} from '../client/bp-graphql-client-defs';
import { getFileUrl } from './getFileUrl';

export const handleFileEntryDownload = async (uuid: string) => {
  const { data } = await urqlClient
    .query<BpFileEntriesQuery, BpFileEntriesQueryVariables>(
      BpFileEntriesDocument,
      {
        where: {
          uuid: uuid,
        },
      },
      { requestPolicy: 'network-only' },
    )
    .toPromise();
  const token = data?.fileEntries[0].downloadToken;
  const link = document.createElement('a');
  link.download = data?.fileEntries[0].filename ?? '';
  link.href = getFileUrl(uuid, token ?? '');
  link.target = '_blank';
  link.click();
};
