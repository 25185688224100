import { useDeleteBpSchoolyearMutation, useSchoolYearsQuery } from '../../../client/bp-graphql-client-defs';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useMemoizedCacheTag } from '../../../hooks/useMemoizedCacheTag';
import {
  Button,
  DotsVerticalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  Modal,
  Row,
  Table,
  TableColumns,
} from '@bp/ui-components';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { SchoolyearForm } from 'components/SchoolyearForm/SchoolyearForm';
import { niceDate } from '../../../utils/dateCalculations';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { useConfirm } from '../../../hooks/useConfirm';
import { showErrorToast } from '../../../utils/showErrorToast';
import { showSuccessToast } from '../../../utils/showSuccessToast';

export type SchoolyearData = {
  name: string;
  uuid: string;
  start: string;
  end: string;
  managedBy: string;
};

export const InstitutionSchoolyearsSubpage = () => {
  const { t } = useTranslation();
  const perms = usePermissionChecker();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const context = useMemoizedCacheTag('GROUP');

  const [, deleteSchoolyear] = useDeleteBpSchoolyearMutation();

  const [currentSchoolyear, setCurrentSchoolyear] = useState<SchoolyearData | undefined>();
  const [modalOpen, setModalOpen] = useState(false);

  const [{ data }] = useSchoolYearsQuery({
    context,
    variables: {
      where: {
        organization: {
          uuid: organizationUuid,
        },
      },
    },
  });

  const Schoolyears = data?.schoolYears;

  const tableColumns = useMemo((): TableColumns<SchoolyearData>[] => {
    return [
      {
        header: t('schoolyears.titleSingular'),
        id: 'name',
        cell: ({ row }) => row.original.name,
        canExpand: true,
        size: 300,
      },
      {
        id: 'begin_end',
        header: t('common.timeframe'),
        cell: ({ row }) => `${niceDate(row.original.start, 'short')} - ${niceDate(row.original.end, 'short')}`,
        size: 400,
      },
      {
        header: t('common.managedBy'),
        id: 'managedBy',
        cell: ({ row }) => row.original.managedBy,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableData = useMemo(() => Schoolyears ?? [], [Schoolyears]);

  const handleClose = () => {
    setModalOpen(false);
    setCurrentSchoolyear(undefined);
  };
  const { ConfirmationDialog, confirm: confirmDelete } = useConfirm({
    defaultTitle: t('common.delete'),
    defaultConfirmText: t('delete.confirmText'),
  });

  const handleEdit = useCallback((row: Row<SchoolyearData>) => {
    setCurrentSchoolyear(row.original);
    setModalOpen(true);
  }, []);

  const memoizedHandleDelete = useCallback(
    async (row: Row<SchoolyearData>) => {
      if (perms?.canDeleteSchoolyear({ uuid: organizationUuid })) {
        const { error } = await deleteSchoolyear({ uuid: row.original.uuid }, context);
        error ? showErrorToast(error) : showSuccessToast(t('schoolyears.deleted'));
      }
    },
    [context, deleteSchoolyear, organizationUuid, perms, t],
  );

  const dropDownMenu = (row: Row<SchoolyearData>): DropdownMenuItem[] => {
    return [
      { label: t('common.edit'), onClick: () => handleEdit(row) },

      {
        label: t('delete.title'),
        type: 'error',
        onClick: async () => {
          const res = await confirmDelete();
          if (res) {
            await memoizedHandleDelete(row);
          }
        },
      },
    ];
  };

  return (
    <BpSubpage>
      {pimAuthClaims.getOrganization() && (
        <Table
          breakpoint='580px'
          data-cy={'schoolyearsList'}
          showActionBar
          showBorderRadius
          showShadow
          isOnWhite={false}
          actionBarSettings={{
            showGlobalFilter: false,
            showAddButton: perms?.canCreateSchoolyear({ uuid: organizationUuid }),
            addButtonText: t('common.addType', { type: t('schoolyears.titleSingular') }),
          }}
          sorting={[{ id: 'name', desc: false }]}
          onAddClick={() => {
            setModalOpen(true);
          }}
          columns={tableColumns}
          data={tableData}
          lastCol={(row) => {
            return perms?.canUpdateSchoolyear({
              uuid: organizationUuid,
            }) ? (
              <Dropdown trigger={<Button hierarchy={'tertiary'} icon={<DotsVerticalIcon />} />}>
                <DropdownMenu data={dropDownMenu(row)} />
              </Dropdown>
            ) : null;
          }}
        />
      )}

      <Modal title={t('common.schoolyear')} isOpen={modalOpen} onRequestClose={handleClose} width='s'>
        <SchoolyearForm onClose={handleClose} schoolyear={currentSchoolyear} />
      </Modal>
      <ConfirmationDialog />
    </BpSubpage>
  );
};
