import { BpCalendar } from '../../../components/BpCalendar/BpCalendar';
import { BpCard } from '../../../components/BpCard/BpCard';
import { EventsList } from '../../../components/EventsList/EventsList';
import { FC, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppointmentForm } from '../../../components/AppointmentForm/AppointmentForm';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { useGroupCalendarEvents } from '../../../hooks/useCalendarEvents';
import { findClosestEventToNow } from '../../../utils/dateCalculations';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { Grid, GridColumn, GridRow } from '@bp/ui-components';

export const GroupAppointmentsSubpage: FC = () => {
  const { uuid, groupUuid, add, edit } = useParams<{
    groupUuid: string;
    uuid: string;
    add: string;
    edit: string;
  }>();

  const navigate = useNavigate();
  const { pimAuthClaims } = useAuthClaims();

  const perms = usePermissionChecker();
  const isAddEditAllowed = perms?.canCreateBpGroupEvents({
    uuid: groupUuid ?? '',
    organization: { uuid: pimAuthClaims.getOrganizationUuid() },
  });

  const events = useGroupCalendarEvents(groupUuid ?? '');
  const [scrollUuid, setScrollUuid] = useState<string | null>(findClosestEventToNow(events)?.uuid ?? null);
  const [randomRedrawString, setRandomRedrawString] = useState<string>('');

  const showForm = useMemo(() => {
    return (isAddEditAllowed && (add || edit)) ?? false;
  }, [isAddEditAllowed, add, edit]);

  const appointmentUuid = useMemo(() => {
    return uuid ?? false;
  }, [uuid]);

  return (
    <BpSubpage>
      {showForm && groupUuid ? (
        <AppointmentForm
          courseUuid={groupUuid}
          canAssignTeachingUnit={false}
          disableEventTypeValues={['lesson', 'exam']}
          appointmentUuid={appointmentUuid ? appointmentUuid : undefined}
          onClose={() => navigate(`/groups/${groupUuid}/appointments/`)}
        />
      ) : (
        <Grid>
          <GridRow mobileGap='var(--grid-column-gap)'>
            <GridColumn width={4}>
              <BpCard className='pb-6 pr-6 pl-6' noPadding showBorder hideBackground>
                <BpCalendar
                  events={events}
                  showAddButton={false}
                  eventClick={(uuid) => {
                    setRandomRedrawString(Date.now().toString());
                    setScrollUuid(uuid);
                  }}
                />
              </BpCard>
            </GridColumn>
            <GridColumn width={8}>
              <EventsList
                courseUuid={groupUuid}
                fullHeight
                showHeader
                showCompactEvents={false}
                events={events}
                scrollToUuid={scrollUuid}
                randomRedrawString={randomRedrawString}
                onAddClick={() => {
                  navigate(`/groups/${groupUuid}/appointments/create`);
                }}
                onEdit={
                  isAddEditAllowed
                    ? (uuid) => {
                        navigate(`/groups/${groupUuid}/appointments/edit/${uuid}`);
                      }
                    : undefined
                }
              />
            </GridColumn>
          </GridRow>
        </Grid>
      )}
    </BpSubpage>
  );
};
