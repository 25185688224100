import { LazyLoader } from '@bp/ui-components';
import { BpPage } from '../../components/BpPage/BpPage';
import { NavigationTabs, NavigationTabsType } from '../../components/NavigationTabs/NavigationTabs';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { FC, Suspense, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatches, useParams } from 'react-router-dom';
import { useGroupAsGroupsQuery } from '../../client/bp-graphql-client-defs';
import { OrganizationConfigContext } from '../../context/OrganizationConfigContext';

export const GroupPage: FC = () => {
  const { t } = useTranslation();
  const { groupUuid } = useParams();

  const { cloudLicense, cloudLink } = useContext(OrganizationConfigContext);

  // hack hack hack for overview
  const matches = useMatches();
  const last = [...matches].pop();

  const context = useMemoizedCacheTag('GROUP');

  const [{ data }] = useGroupAsGroupsQuery({ variables: { where: { uuid: groupUuid } }, context });
  const currentGroup = data?.groups[0];

  const tabs: NavigationTabsType[] = [
    {
      title: t('overview.title'),
      path: `/groups/${groupUuid}`,
      active: last?.id === 'group-overview',
    },
    {
      title: t('workmaterials.title'),
      path: `/groups/${groupUuid}/workmaterials`,
    },
    {
      title: t('appointments.title'),
      path: `/groups/${groupUuid}/appointments`,
    },
    {
      title: t('persons.title'),
      path: `/groups/${groupUuid}/persons`,
    },
    {
      title: 'Cloud', // TODO: external-link Icon
      path: cloudLink,
      target: '_blank',
      disabled: !cloudLicense || cloudLink === '' || !currentGroup?.externalServices?.nextcloud?.enabled,
    },
  ];

  return (
    <BpPage title={currentGroup?.name ?? t('groups.title')}>
      <NavigationTabs className='mb-6' tabs={tabs} />
      <Suspense fallback={<LazyLoader embedded transparent forceHeight='45vh' />}>{<Outlet />}</Suspense>
    </BpPage>
  );
};
