import styles from '../_Flyouts.module.scss';
import { NotificationType } from '../../../../utils/matrixClient';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { BpLink } from '../../../../components/BpLink/BpLink';

export const NotificationItem = ({
  notification,
  onClick,
}: {
  notification: NotificationType;
  onClick: (notification: NotificationType) => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles['flyout-item']}>
      <div className={styles.left}>
        <div dangerouslySetInnerHTML={{ __html: notification.content ?? '' }}></div>
      </div>
      <div className={styles.right}>
        <div className={styles.date}>{dayjs(notification.date).fromNow()}</div>
        {notification.targetUrl !== '' && (
          <BpLink className={styles.link} value={t('common.show')} onNavigate={() => onClick(notification)} />
        )}
      </div>
    </div>
  );
};
