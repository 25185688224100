import styles from '../_Flyouts.module.scss';
import { useTranslation } from 'react-i18next';
import { EmptyState, NoTasksIcon } from '@bp/ui-components';
import classNames from 'classnames';
import { NotificationItem } from './NotificationItem';
import { NotificationType } from 'utils/matrixClient';
import { useMatrixClient } from '../../../../hooks/matrix/useMatrixClient';
import { useRefreshOnEvent } from '../../../../hooks/matrix/useRefreshOnEvent';
import { useMatrixAvailable } from '../../../../hooks/matrix/useMatrixAvailable';

type NotificationsProps = {
  notifications: NotificationType[];
  onClick: (url: string) => void;
  className?: string;
};

export function Notifications({ notifications, onClick, className }: NotificationsProps) {
  const { t } = useTranslation();
  const matrixClient = useMatrixClient();
  useRefreshOnEvent();
  useMatrixAvailable();

  const handleNotificationClick = async (notification: NotificationType) => {
    notification.targetUrl && onClick(notification.targetUrl);
    const eventId = notification.eventId;
    const roomId = notification.roomId;
    eventId && roomId && (await matrixClient?.setRoomReadMarkers(roomId, eventId));
  };

  return (
    <div className={classNames(styles['flyout-body'], 'has-scrollbar', className)}>
      {notifications?.length === 0 ? (
        <EmptyState
          className={styles.empty}
          forcedHeight='400px'
          fitParent
          padding='xl'
          borderRadius='none'
          title={t('notifications.noNotifications')}
          icon={<NoTasksIcon className='svg-icon' />}
          iconColor='var(--color-primary-light)'
        />
      ) : (
        notifications?.map((n) => (
          <NotificationItem key={n.eventId} notification={n} onClick={handleNotificationClick} />
        ))
      )}
    </div>
  );
}
