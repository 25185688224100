import {
  BpCalendarEventsQuery,
  useBpBirthdayEventsQuery,
  useBpCalendarEventsQuery,
} from '../client/bp-graphql-client-defs';
import { useMemoizedCacheTag } from './useMemoizedCacheTag';
import { useAuthClaims } from './useAuthClaims';
import { BpEventType } from '../components/EventsList/EventsList';
import { uniqueByUuid } from '../utils/uniquByUuid';
import { isNotEmpty } from '../utils/type-guards';
import dayjs from 'dayjs';
import { eventColors } from '../utils/dateCalculations';
import { useMemo } from 'react';

export const useDashboardCalendarEvents = (
  profileUuid: string,
): { dashboardEvents: BpEventType[]; refetchDashboardEvents: () => void } => {
  const { pimAuthClaims } = useAuthClaims();
  const organization = pimAuthClaims.getOrganization();
  const context = useMemoizedCacheTag('EVENT');
  const birthdays = useBirthdays();

  const [{ data: eventData }, refetch] = useBpCalendarEventsQuery({
    variables: {
      where: {
        OR: [
          {
            holderConnection: {
              Organization: {
                node: { uuid: organization.uuid },
              },
            },
          },
          {
            attendees_SOME: {
              uuid: profileUuid,
            },
          },
          {
            owner: {
              uuid: profileUuid,
            },
          },
        ],
      },
    },
    context,
  });

  const events: BpEventType[] = [...(eventData?.calendarEvents ? eventData.calendarEvents : [])].map((e) => {
    const eventColor = eventColors(e.categories ?? []);

    return {
      uuid: e.uuid,
      virtualLocationsCount: e.virtualLocations.length,
      virtualLocations:
        e.virtualLocations.map((v) => ({
          uuid: v.uuid,
          features: v.features ?? [],
          uri: import.meta.env.VITE_APP_REDIRECT_URI + '/join/' + v.token,
          running: v.running,
          token: v.token,
        })) ?? [],
      owner: e.owner.uuid === profileUuid,
      locationsCount: e.locations.length,
      maxAttendees: e.maxAttendees ?? 0,
      guestsAllowed: e.guestsAllowed ?? false,
      attendees: e.attendeesConnection.edges.map((p) => ({ ...p.node, displayName: p.node.displayName ?? '' })),
      start: dayjs(e.start),
      color: eventColor,
      type: 'event',
      categories: e.categories ?? [],
      description: e.description ?? '',
      title: e.title ?? '',
      duration: e.duration ?? undefined,
      keywords: e.keywords ?? [],
      locale: e.locale ?? '',
      status: e.status ?? '',
      until: e.until ? dayjs(e.until) : undefined,
      originDisplayName:
        e.holderConnection.edges.find((h) => (h.node.displayName ?? '').length > 0)?.node.displayName ?? '',
      originUuid: e.holderConnection.edges.find((h) => (h.node.uuid ?? '').length > 0)?.node.uuid ?? '',
    };
  });
  return {
    dashboardEvents: uniqueByUuid(
      [...events, ...birthdays].sort((a, b) => {
        const startA = new Date(a.start.toDate()).getTime();
        const startB = new Date(b.start.toDate()).getTime();
        return startA - startB;
      }),
    ),
    refetchDashboardEvents: refetch,
  };
};

export const useCourseCalendarEvents = (uuid: string): BpEventType[] => {
  const events = useCalendarEvents([uuid]);
  const birthdays = useBirthdays(undefined, uuid);
  return [...events, ...birthdays].sort((a, b) => {
    const startA = a.start.unix();
    const startB = b.start.unix();
    return startA - startB;
  });
};

export const useGroupOrCoursesOnlineEvents = (uuids: string[]): BpEventType[] => {
  const events = useCalendarEvents(uuids, true);
  return events.sort((a, b) => {
    const startA = a.start.unix();
    const startB = b.start.unix();
    return startA - startB;
  });
};

export const useGroupCalendarEvents = (uuid: string) => {
  const events = useCalendarEvents([uuid]);
  const birthdays = useBirthdays(uuid, undefined);
  return [...events, ...birthdays].sort((a, b) => {
    const startA = a.start.unix();
    const startB = b.start.unix();
    return startA - startB;
  });
};

const useCalendarEvents = (uuid: string[], onlyOnline: boolean = false): BpEventType[] => {
  const context = useMemoizedCacheTag('EVENT');
  const [{ data }] = useBpCalendarEventsQuery({
    variables: {
      where: {
        holderConnection: {
          Group: {
            node: {
              uuid_IN: uuid,
            },
          },
        },
        ...(onlyOnline
          ? {
              virtualLocations_SOME: {
                running: true,
              },
            }
          : undefined),
      },
    },
    context,
  });

  return mapCalendarEventsToBpEventType(data?.calendarEvents ?? []);
};

const useBirthdays = (groupUuid?: string, courseUuid?: string): BpEventType[] => {
  const [{ data }] = useBpBirthdayEventsQuery({ variables: { groupUuid: groupUuid, courseUuid: courseUuid } });

  return (
    data?.birthdayEvents
      .map((bE) => ({
        uuid: bE.uuid,
        title: bE.title ?? '',
        description: undefined,
        keywords: [],
        categories: [],
        type: 'birthday',
        color: eventColors(bE.categories ?? [], true),
        owner: false,
        guestsAllowed: false,
        start: dayjs(bE.start),
        attendees: [],
        locationsCount: 0,
        virtualLocationsCount: 0,
        locations: [],
        virtualLocations: [],
      }))
      .filter(isNotEmpty) ?? []
  );
};

export const useRunningPlannedMeetings = (
  profileUuid: string,
): { runningMeetings: BpEventType[]; plannedMeetings: BpEventType[]; refetchDashboardEvents: () => void } => {
  const { dashboardEvents, refetchDashboardEvents } = useDashboardCalendarEvents(profileUuid);

  const { runningMeetings, plannedMeetings } = useMemo(() => {
    const plannedMeetings: BpEventType[] = [];
    const runningMeetings: BpEventType[] = [];

    dashboardEvents
      .filter(
        (e) =>
          e.virtualLocationsCount > 0 &&
          ((dayjs().add(1, 'days').isAfter(e.start) && dayjs().subtract(Number(e.duration), 's').isBefore(e.start)) ||
            e.virtualLocations.find((v) => v.running)?.running),
      )
      .forEach((e) => {
        if (e.virtualLocations.find((v) => v.running)) {
          runningMeetings.push(e);
        } else {
          plannedMeetings.push(e);
        }
      });

    return { runningMeetings, plannedMeetings };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardEvents, refetchDashboardEvents]);

  return {
    runningMeetings: runningMeetings,
    plannedMeetings: plannedMeetings,
    refetchDashboardEvents,
  };
};

export const mapCalendarEventsToBpEventType = (
  calendarEvents: BpCalendarEventsQuery['calendarEvents'],
): BpEventType[] => {
  return calendarEvents?.map((e) => {
    const eventColor = eventColors(e.categories ?? []);

    return {
      uuid: e.uuid,
      guestsAllowed: e.guestsAllowed ?? false,
      maxAttendees: e.maxAttendees ?? 0,
      virtualLocationsCount: e.virtualLocations.length,
      virtualLocations:
        e.virtualLocations.map((v) => ({
          uuid: v.uuid,
          features: v.features ?? [],
          uri: import.meta.env.VITE_APP_REDIRECT_URI + '/join/' + v.token,
          running: v.running,
          token: v.token,
        })) ?? [],
      owner: false, // TODO,
      locationsCount: e.locations.length,
      classbookNote: e.classbookNote ?? undefined,
      classbookNoteContentType: e.classbookNoteContentType ?? undefined,
      classbookHomework: e.classbookHomework ?? undefined,
      classbookHomeworkContentType: e.classbookHomeworkContentType ?? undefined,
      attendees: e.attendeesConnection.edges.map((p) => {
        return { ...p.properties, ...p.node, displayName: p.node.displayName ?? '' };
      }),
      start: dayjs(e.start),
      color: eventColor,
      type: 'event',
      categories: e.categories ?? [],
      description: e.description ?? '',
      title: e.title ?? '',
      duration: e.duration ?? undefined,
      keywords: e.keywords ?? [],
      locale: e.locale ?? '',
      status: e.status ?? '',
      until: e.until ? dayjs(e.until) : undefined,
      originDisplayName:
        e.holderConnection.edges.find((h) => (h.node.displayName ?? '').length > 0)?.node.displayName ?? '',
      originUuid: e.holderConnection.edges.find((h) => (h.node.uuid ?? '').length > 0)?.node.uuid ?? '',
    };
  });
};
