import { Form, Formik } from 'formik';
import { Checkbox, Grid, GridColumn, GridRow, MailIcon, MobilePhoneIcon } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import styles from './NotificationSettingsForm.module.scss';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { showErrorToast } from '../../utils/showErrorToast';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { CombinedError } from 'urql';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import {
  ProfileNotificationPreferencesConnectFieldInput,
  useCreateNotificationPreferencesMutation,
  usePreferencesQuery,
  useUpdateProfileMutation,
} from '../../client/bp-graphql-client-defs';
import { showSuccessToast } from '../../utils/showSuccessToast';

type NotificationSettingType = {
  name: string;
  value: string[];
}[];

type NotificationSettingsFormProps = {
  data: NotificationSettingType | null | undefined;
  onClose: () => void;
};

// TODO: in diese „Channels“ müssen die "echten" Rooms sinnvoll zusammengeführt werden
enum NotificationChannels {
  NewMaterial = 'newMaterial',
  MaterialUpdated = 'materialUpdated',
  MaterialDeleted = 'materialDeleted',
  NewMessage = 'newMessage',
  AssignmentOpened = 'assignmentOpened',
  AssignmentUpdated = 'assignmentUpdated',
  AssignmentDeleted = 'assignmentDeleted',
  SubmissionNew = 'submissionNew',
  SubmissionUpdated = 'submissionUpdated',
  NewAnnouncement = 'newAnnouncement',
}

export const NotificationSettingsForm = ({ data, onClose }: NotificationSettingsFormProps) => {
  const { t } = useTranslation();
  const pimAuthClaims = useAuthClaims().pimAuthClaims;
  const perms = usePermissionChecker();

  const [{ data: preferenceData }] = usePreferencesQuery({ variables: { where: {} } });
  const [, createPreferences] = useCreateNotificationPreferencesMutation();

  const handleClose = () => {
    onClose();
  };

  function getServices(name: string) {
    return data?.find((n) => n.name === name)?.value ?? [];
  }

  const initialValues = {
    assignmentOpened: getServices('assignmentOpened'),
    assignmentUpdated: getServices('assignmentUpdated'),
    assignmentDeleted: getServices('assignmentDeleted'),
    submissionNew: getServices('submissionNew'),
    submissionUpdated: getServices('submissionUpdated'),
    newMaterial: getServices('newMaterial'),
    materialUpdated: getServices('materialUpdated'),
    materialDeleted: getServices('materialDeleted'),
    newMessage: getServices('newMessage'),
    newAnnouncement: getServices('newAnnouncement'),
  };

  const checkboxes = [
    {
      group: t('assignments.title'),
      boxes: [
        { channel: NotificationChannels.AssignmentOpened, label: t('notifications.newAssignment') },
        { channel: NotificationChannels.AssignmentUpdated, label: t('notificationSettings.assignmentUpdated') },
        { channel: NotificationChannels.AssignmentDeleted, label: t('notificationSettings.assignmentDeleted') },
        { channel: NotificationChannels.SubmissionNew, label: t('notifications.newSubmission') },
        { channel: NotificationChannels.SubmissionUpdated, label: t('notificationSettings.submissionUpdated') },
      ],
    },
    {
      group: t('workmaterials.title'),
      boxes: [
        { channel: NotificationChannels.NewMaterial, label: t('notifications.newMaterial') },
        { channel: NotificationChannels.MaterialUpdated, label: t('notifications.materialUpdated') },
        { channel: NotificationChannels.MaterialDeleted, label: t('notifications.materialDeleted') },
      ],
    },
    {
      group: t('messages.title'),
      boxes: [
        { channel: NotificationChannels.NewMessage, label: t('notificationSettings.newMessage') },
        { channel: NotificationChannels.NewAnnouncement, label: t('notificationSettings.newAnnouncement') },
      ],
    },
  ];

  const context = useMemoizedCacheTag('PROFILE');

  const [, updateProfile] = useUpdateProfileMutation();

  const onSubmit = async (values: typeof initialValues) => {
    if (perms?.canUpdateProfile(pimAuthClaims.getProfile())) {
      const existingPreferences = preferenceData?.preferences.map((p) => p.name);
      const missingPreferences = Object.keys(values).filter((name) => !existingPreferences?.includes(name));
      if (missingPreferences.length > 0) {
        await createPreferences({ input: missingPreferences.map((name) => ({ name })) });
      }
      //
      // payload = [{name: newSubmission, value:	'["mail","push"]'}]"
      //
      const payload: ProfileNotificationPreferencesConnectFieldInput[] = Object.entries(values).map(
        ([name, value]) => ({
          overwrite: true,
          where: { node: { name } },
          edge: { transports: value },
        }),
      );
      const resp = await updateProfile(
        {
          uuid: pimAuthClaims.getProfile().uuid,
          update: {
            notificationPreferences: [{ connect: payload }],
          },
        },
        context,
      );
      if (resp.error) {
        showErrorToast(resp.error);
      } else {
        showSuccessToast(t('common.saved'));
        handleClose();
      }
    }

    if (!perms?.canUpdateProfile(pimAuthClaims.getProfile())) {
      showErrorToast({ message: t('common.notAllowed') } as CombinedError);
      handleClose();
    }
  };

  return (
    <Formik<typeof initialValues> initialValues={initialValues} onSubmit={onSubmit}>
      {({ resetForm, setFieldValue, values, isSubmitting, errors }) => {
        const closeForm = () => {
          resetForm();
          handleClose();
        };

        type Channels = 'mail' | 'push';

        const handleClick = (key: keyof typeof initialValues, channel: Channels) => {
          const newValue = values[key];
          const index = newValue.indexOf(channel);
          if (index !== -1) {
            newValue.splice(index, 1);
          } else {
            newValue.push(channel);
          }
          setFieldValue(key, newValue);
        };

        const handleSelect = (select: boolean) => {
          for (const key in initialValues) {
            let value = values[key as keyof typeof values] as Array<Channels>;
            value = [];
            if (select) value = ['mail', 'push'];
            setFieldValue(key, value);
          }
        };

        return (
          <Form>
            <Grid className={styles['notifications-settings-form']}>
              <GridRow mobileGap='var(--grid-column-gap)'>
                {checkboxes.map((c, i) => (
                  <GridColumn key={i} width={4}>
                    <div className={styles.header}>
                      <MobilePhoneIcon />
                      <MailIcon />
                      <span className={styles.group}>{c.group}</span>
                    </div>
                    {c.boxes.map((b, i) => {
                      return (
                        <div key={i} className={styles.boxes}>
                          <Checkbox
                            className={styles.push}
                            checked={values[b.channel].includes('push')}
                            name={`${b.channel}_push`}
                            onChange={() => handleClick(b.channel, 'push')}
                          />
                          <Checkbox
                            className={styles.mail}
                            checked={values[b.channel].includes('mail')}
                            name={`${b.channel}_mail`}
                            onChange={() => handleClick(b.channel, 'mail')}
                          />
                          <div className={styles.label}>{b.label}</div>
                        </div>
                      );
                    })}
                  </GridColumn>
                ))}
              </GridRow>
            </Grid>
            <ModalBottomButtons
              errors={errors}
              closeButton={{ callback: closeForm }}
              isLoading={isSubmitting}
              additionalButtons={[
                { text: t('notifications.selectAll'), hierarchy: 'tertiary', callback: () => handleSelect(true) },
                { text: t('notifications.deselectAll'), hierarchy: 'tertiary', callback: () => handleSelect(false) },
              ]}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
