import { AlertDialog, Button, DotsVerticalIcon, Dropdown, DropdownMenu, Table } from '@bp/ui-components';
import { Row } from '@tanstack/react-table';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { niceDate } from '../../utils/dateCalculations';
import { BpLink } from 'components/BpLink/BpLink';

export type AppointmentsPreviewTableType = {
  uuid: string;
  name: string;
  date: string;
};

type AppointmentsPreviewTableProps = {
  data: AppointmentsPreviewTableType[];
  onEdit: (uuid: string) => void;
  onNavigate: (uuid: string) => void;
  onDelete: (uuid: string) => void;
};

export const AppointmentsPreviewTable: FC<AppointmentsPreviewTableProps> = ({ data, onNavigate, onEdit, onDelete }) => {
  const { t } = useTranslation();

  return (
    <Table<AppointmentsPreviewTableType>
      maxHeight={220}
      className='hide-gutter'
      canScroll
      hideHeader
      showSort
      customPadding='var(--spacing-6)'
      customRowHeight='var(--list-row-height)'
      customLastColSpacing='var(--spacing-3)'
      lastColWidth='150px'
      breakpoint={null}
      data={data}
      columns={[
        {
          id: 'name',
          accessorKey: 'name',
          cell: ({ row }: { row: Row<AppointmentsPreviewTableType> }) => (
            <BpLink value={row.original.name} onNavigate={() => onNavigate(row.original.uuid)} />
          ),
          canExpand: true,
        },
      ]}
      emptyStateSettings={{
        hideIcon: true,
        size: 'small',
        title: '',
        subtitle: t('appointments.noAppointments'),
        forcedHeight: '150px',
        fitParent: true,
      }}
      lastCol={(row: Row<AppointmentsPreviewTableType>) => {
        return (
          <div className='bp__last-col'>
            <div>{`${t('common.on')} ${niceDate(row.original.date, 'short', 'short')}`}</div>
            <Dropdown trigger={<Button hierarchy='ghost' icon={<DotsVerticalIcon className='svg-icon' />} />}>
              <DropdownMenu
                data={[
                  {
                    label: t('common.edit'),
                    onClick: () => {
                      onEdit(row.original.uuid);
                    },
                  },
                  {
                    type: 'ruler',
                    label: '',
                  },
                  {
                    type: 'component',
                    label: t('common.delete'),
                    node: (
                      <AlertDialog
                        title={t('delete.title')}
                        confirmText={t('delete.title')}
                        cancelText={t('delete.abort')}
                        onConfirm={() => {
                          onDelete(row.original.uuid);
                        }}
                        trigger={t('common.delete')}
                      >
                        {t('delete.confirmText')}
                      </AlertDialog>
                    ),
                  },
                ]}
              />
            </Dropdown>
          </div>
        );
      }}
    />
  );
};
