import { useTranslation } from 'react-i18next';
import { Tab } from '@bp/ui-components';
import { Classbook } from 'components/Classbook/Classbook';
import { BpPage } from 'components/BpPage/BpPage';
import { ClassbookClasses } from '../../components/ClassbookClasses/ClassbookClasses';

export const ClassbookPage = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      title: t('classbook.courses'),
      value: 'courses',
      content: <Classbook />,
    },
    {
      title: t('classbook.class'),
      value: 'class',
      content: <ClassbookClasses />,
    },
  ];

  return (
    <BpPage title={t('classbook.title')}>
      <Tab tabs={tabs} defaultValue={tabs[0].value} />
    </BpPage>
  );
};
