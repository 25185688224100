import { useEffect, useRef, useState } from 'react';
import styles from './MatrixConversation.module.scss';
import { Button, EmptyState, Input, RoomIcon, SendIcon } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useGetMatrixRoom } from '../../../hooks/matrix/useGetMatrixRoom';
import { useMatrixClient } from '../../../hooks/matrix/useMatrixClient';
import { MatrixConversationItem } from './MatrixConversationItem/MatrixConversationItem';
import { MatrixEvent } from 'matrix-js-sdk';
import { useMatrixRoomMessagesAsConversation } from '../../../hooks/matrix/useMatrixRoomMessagesAsConversation';

type ConversationProps = {
  selectedRoom?: string | null;
  conversation?: ConversationType;
  onSend: (roomId: string, input: string) => void;
};

export type ConversationType = {
  initials: string;
  isIncoming: boolean;
  color: string;
  id: string;
  messages: {
    type: string;
    content: string;
    date: Date;
    matrixEvent?: MatrixEvent;
  }[];
}[];

export const MatrixConversation = ({ selectedRoom, onSend }: ConversationProps) => {
  const { t } = useTranslation();
  const matrixClient = useMatrixClient();
  const divRef = useRef<HTMLDivElement | null>(null);
  const [input, setInput] = useState('');
  const [preventScroll, setPreventScroll] = useState<boolean>(false);

  const room = useGetMatrixRoom(selectedRoom ?? '');
  const [roomLimit, setRoomLimit] = useState<number>(5);
  const [showLoadMore] = useState(false);
  const conversation = useMatrixRoomMessagesAsConversation(selectedRoom ?? '');

  // smooth scroll after submit and for incoming messages
  useEffect(() => {
    if (divRef.current && !preventScroll) {
      divRef.current.scrollTo({ top: divRef.current.scrollHeight, behavior: 'smooth' });
    }
  }, [preventScroll, divRef, conversation, divRef.current?.scrollHeight, input]);

  // jump to end on room selection
  useEffect(() => {
    if (divRef.current && !preventScroll) {
      divRef.current.scrollTo({ top: divRef.current.scrollHeight, behavior: 'auto' });
    }
  }, [preventScroll, divRef, selectedRoom, conversation]);

  useEffect(() => {
    setPreventScroll(false);
  }, [selectedRoom, setPreventScroll]);

  return (
    <div className={styles.conversation}>
      {!selectedRoom ? (
        <EmptyState
          fitParent
          icon={<RoomIcon className='svg-icon' />}
          iconColor='var(--color-primary-light)'
          title={t('messages.chooseRoom')}
        />
      ) : (
        <>
          <div className={classNames(styles.chat, 'has-scrollbar')} ref={divRef}>
            {showLoadMore && (
              <div
                className={styles['load-more']}
                onClick={async () => {
                  if (room) {
                    setPreventScroll(true);
                    await matrixClient?.scrollback(room, roomLimit);
                    setRoomLimit(roomLimit + 5);
                  }
                }}
              >
                {t('messages.loadMore')}
              </div>
            )}
            {conversation.map((chunk) => {
              return <MatrixConversationItem chunk={chunk} key={chunk.id} />;
            })}
          </div>
          <div className={styles['input-bar']}>
            <Input
              className={styles.input}
              dense
              placeholder={t('messages.typeMessage')}
              onChange={(value) => setInput(value.target.value)}
              value={input}
              name={'input'}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSend(selectedRoom, input);
                  setPreventScroll(false);
                  setInput('');
                }
              }}
              suffix={
                <Button
                  className={styles.send}
                  icon={<SendIcon className='svg-icon white' />}
                  onClick={() => {
                    onSend(selectedRoom, input);
                    setPreventScroll(false);
                    setInput('');
                  }}
                />
              }
            />
          </div>
        </>
      )}
    </div>
  );
};
