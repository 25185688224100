import {
  Button,
  CoursesIcon,
  DotsVerticalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  LazyLoader,
  Modal,
  Row,
  Table,
  TableColumns,
} from '@bp/ui-components';
import { GroupType, useBpDeleteGroupMutation, useGroupAsCourseQuery } from '../../../client/bp-graphql-client-defs';
import { CourseForm } from '../../../components/CourseForm/CourseForm';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useConfirm } from '../../../hooks/useConfirm';
import { useMemoizedCacheTag } from '../../../hooks/useMemoizedCacheTag';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CourseSettingsForm } from '../../../components/CourseSettingsForm/CourseSettingsForm';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import styles from './InstitutionSubpages.module.scss';
import { getExternalService } from '../../../utils/getExternalServiceHelper';
import { CourseTableType } from '../../../components/CoursesTable/CoursesTableTeacher';
import dayjs from 'dayjs';
import { GroupFormOther } from '../../../components/GroupForm/GroupFormOther';
import { BpLink } from '../../../components/BpLink/BpLink';
import { updateClaims } from '../../../utils/authStore';

export const InstitutionCoursesSubpage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const perms = usePermissionChecker();
  const context = useMemoizedCacheTag('GROUP');
  const [claimsTrigger, setClaimsTrigger] = useState<boolean>(false);

  const { pimAuthClaims } = useAuthClaims();

  const [settingsModalOpen, setSettingsModalOpen] = useState<boolean>(false);
  const [formModalOpen, setFormModalOpen] = useState<boolean>(false);
  const [formProfilesOpen, setFormProfilesOpen] = useState<boolean>(false);
  const [currentCourseUuid, setCurrentCourseUuid] = useState<string>('');

  const [, deleteCourse] = useBpDeleteGroupMutation();

  const [{ data }] = useGroupAsCourseQuery({
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
        type: GroupType.Course,
      },
    },
    context,
  });

  const tableData: CourseTableType[] = useMemo(() => {
    return (
      data?.groups.map((course) => {
        return {
          uuid: course.uuid,
          organizationUuid: course.organization.uuid,
          name: course.name,
          meetingInProgress: course.externalServices?.matrix ?? false,
          count: 0,
          newMessage: false,
          community: course.isCommunity,
          source:
            course.managedBy !== 'bp'
              ? course.managedBy
              : course.admins.map((admin) => admin.displayNameShort).toString(),
          nextcloud: {
            enabled: course.externalServices?.nextcloud?.enabled ?? false,
            lastSync: course.externalServices?.nextcloud?.lastSync
              ? dayjs(course.externalServices?.nextcloud.lastSync)
              : undefined,
          },
          threema: {
            enabled: course.externalServices?.threema?.enabled ?? false,
            lastSync: course.externalServices?.threema?.lastSync
              ? dayjs(course.externalServices?.threema.lastSync)
              : undefined,
          },
        };
      }) ?? []
    );
  }, [data]);

  const { ConfirmationDialog, confirm: confirmDelete } = useConfirm({
    defaultTitle: t('common.delete'),
    defaultConfirmText: t('delete.confirmText'),
  });

  const handleSettings = (courseUuid: string) => {
    setSettingsModalOpen(true);
    setCurrentCourseUuid(courseUuid);
  };

  const handleSettingsClose = () => {
    setSettingsModalOpen(false);
    setCurrentCourseUuid('');
  };

  const handleEdit = (uuid: string) => {
    setFormModalOpen(true);
    setCurrentCourseUuid(uuid);
  };

  const handleProfilesEdit = (uuid: string) => {
    setFormProfilesOpen(true);
    setCurrentCourseUuid(uuid);
  };

  const handleAdd = () => {
    setFormModalOpen(true);
  };

  const handleDelete = async (uuid: string) => {
    await deleteCourse(
      {
        id: uuid,
      },
      context,
    );
  };

  const canAdd = perms?.canCreateCourse();
  // full-edit is only available for Groups in my Organization
  const canEdit = (row: Row<CourseTableType>) =>
    perms?.canUpdateCourse({ uuid: row.original.uuid, organization: { uuid: pimAuthClaims.getOrganizationUuid() } }) &&
    row.original.organizationUuid === pimAuthClaims.getOrganizationUuid();

  // for Collaboration Groups only Members are editable
  const canEditProfiles = (row: Row<CourseTableType>) =>
    perms?.canUpdateCourse({ uuid: row.original.uuid, organization: { uuid: pimAuthClaims.getOrganizationUuid() } }) &&
    row.original.organizationUuid !== pimAuthClaims.getOrganizationUuid();

  const canDelete = perms?.canDeleteCourse();

  const columns: TableColumns<CourseTableType>[] = useMemo(() => {
    return [
      {
        header: t('common.designation'),
        id: 'name',
        accessorKey: 'name',
        cell: ({ row }: { row: Row<CourseTableType> }) => {
          return <BpLink value={row.original.name} onNavigate={() => navigate('/courses/' + row.original.uuid)} />;
        },
        canExpand: true,
      },
      {
        header: t('schoolyears.titleSingular'),
        id: 'schoolyearName',
        accessorKey: 'schoolyearName',
      },
      {
        header: 'Community',
        id: 'community',
        accessorKey: 'community',
        type: 'boolean',
        size: 120,
      },
      {
        header: t('common.managedBy'),
        id: 'source',
        accessorKey: 'source',
      },
      {
        header: t('common.nextcloud'),
        id: 'nextcloud',
        accessorKey: 'nextcloud',
        cell: ({ row }) => {
          const service = getExternalService(
            row.original.nextcloud?.enabled ?? false,
            row.original.nextcloud?.lastSync,
          );
          return <div style={{ color: service.color ?? undefined }}>{service.text.toString()}</div>;
        },
        size: 150,
      },
      {
        header: t('common.threema'),
        id: 'threema',
        accessorKey: 'threema',
        cell: ({ row }) => {
          const service = getExternalService(row.original.threema?.enabled ?? false, row.original.threema?.lastSync);
          return <div style={{ color: service.color ?? undefined }}>{service.text.toString()}</div>;
        },
        size: 150,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dropDownMenu = (row: Row<CourseTableType>): DropdownMenuItem[] => {
    const menu: DropdownMenuItem[] = [];

    if (canEdit(row)) {
      menu.push({
        label: t('common.edit'),
        type: 'default',
        onClick: () => handleEdit(row.original.uuid),
      });
    }
    if (canEditProfiles(row)) {
      menu.push({
        label: t('common.editProfiles'),
        type: 'default',
        onClick: () => handleProfilesEdit(row.original.uuid),
      });
    }

    if (canEdit(row)) {
      menu.push({
        label: t('settings.title'),
        type: 'default',
        onClick: () => handleSettings(row.original.uuid),
      });
    }

    if (canDelete) {
      menu.push(
        { type: 'ruler' },
        {
          label: t('delete.title'),
          type: 'error',
          onClick: async () => {
            const res = await confirmDelete();
            if (res) {
              handleDelete(row.original.uuid);
            }
          },
        },
      );
    }

    return menu;
  };

  return (
    <BpSubpage className={styles.courses}>
      {perms?.canCreateCourse() && (
        <Table<CourseTableType>
          breakpoint='580px'
          data-cy='courseList'
          showActionBar
          isOnWhite={false}
          showBorderRadius
          showShadow
          showSort
          actionBarSettings={{
            showAddButton: canAdd,
            addButtonText: t('courses.addCourse'),
            showExpertFilter: true,
          }}
          onAddClick={() => handleAdd()}
          emptyStateSettings={{
            icon: <CoursesIcon />,
          }}
          columns={columns}
          data={tableData}
          {...(canDelete
            ? {
                lastCol: (row) => {
                  return (
                    <Dropdown trigger={<Button hierarchy='ghost' icon={<DotsVerticalIcon />} />}>
                      <DropdownMenu data={dropDownMenu(row)} />
                    </Dropdown>
                  );
                },
              }
            : {})}
        />
      )}

      <Modal
        isOpen={formModalOpen}
        onRequestClose={() => {
          setFormModalOpen(false);
          setCurrentCourseUuid('');
        }}
        title={currentCourseUuid === '' ? t('courses.editCourse') : t('courses.addCourse')}
      >
        <CourseForm
          courseUuid={currentCourseUuid}
          onClose={() => {
            setFormModalOpen(false);
            setCurrentCourseUuid('');
          }}
          asTeacher={false}
        />
      </Modal>

      <Modal
        isOpen={formProfilesOpen}
        onRequestClose={() => {
          setFormProfilesOpen(false);
          setCurrentCourseUuid('');
        }}
        title={t('groups.editGroup')}
        hideFooter
      >
        <Suspense fallback={<LazyLoader embedded forceHeight='30vh' />}>
          <GroupFormOther
            groupUuid={currentCourseUuid}
            onClose={async () => {
              await updateClaims(() => setClaimsTrigger(!claimsTrigger));
              setFormProfilesOpen(false);
              setCurrentCourseUuid('');
            }}
          />
        </Suspense>
      </Modal>

      <Modal
        title={t('courses.coursesSettings')}
        isOpen={settingsModalOpen}
        onRequestClose={handleSettingsClose}
        width='s'
      >
        <CourseSettingsForm onClose={handleSettingsClose} courseUuid={currentCourseUuid} />
      </Modal>

      <ConfirmationDialog />
    </BpSubpage>
  );
};
