import { BpCard } from 'components/BpCard/BpCard';
import { useTranslation } from 'react-i18next';
import styles from './Classbook.module.scss';
import { ArrowLeftIcon, ArrowRightIcon, Button, Grid, GridColumn, GridRow, LazyLoader } from '@bp/ui-components';
import { Suspense, useRef, useState } from 'react';
import { ClassbookEvents } from './ClassbookEvents';
import { BpEventType } from '../EventsList/EventsList';
import { useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { ClassbookEvent } from './ClassbookEvent';
import { useContainerDimensions } from 'utils/dimensions';

export const Classbook = () => {
  const { t } = useTranslation();

  const { dateParam } = useParams();

  const controlsRef = useRef<HTMLDivElement>(null);
  const { isTiny, isSmall } = useContainerDimensions(controlsRef);

  const [selectedDay, setSelectedDay] = useState<Dayjs>(dayjs(dateParam));
  const [bpEvent, setBpEvent] = useState<BpEventType | null>(null);

  const [selected, setSelected] = useState<string | null>(null);

  function getTitle(date: Dayjs): string {
    return date.format(isTiny ? 'DD.MM.YY' : isSmall ? 'DD. MMMM YYYY' : 'dddd, DD. MMMM YYYY');
  }

  function handleEventClick(clickedEvent: BpEventType) {
    if (clickedEvent.uuid === bpEvent?.uuid) return;
    setSelected(clickedEvent.uuid);
    setBpEvent(clickedEvent);
  }

  return (
    <div className={styles.classbook}>
      <Grid>
        <GridRow mobileGap='var(--grid-column-gap)'>
          <GridColumn width={5}>
            <BpCard className={styles.events} noPadding>
              <div className={styles.controls} ref={controlsRef}>
                <Button
                  name='decrease'
                  hierarchy='tertiary'
                  onClick={() => {
                    setSelectedDay(selectedDay.subtract(1, 'day'));
                    setBpEvent(null);
                    setSelected(null);
                  }}
                  icon={<ArrowLeftIcon className='svg-icon' />}
                />
                <div className={styles.title}>{getTitle(selectedDay)}</div>
                <Button
                  name='increase'
                  hierarchy='tertiary'
                  onClick={() => {
                    setSelectedDay(selectedDay.add(1, 'day'));
                    setBpEvent(null);
                    setSelected(null);
                  }}
                  icon={<ArrowRightIcon className='svg-icon' />}
                />
                <Button
                  name='today'
                  className='ml-4'
                  hierarchy='secondary'
                  onClick={() => {
                    setSelectedDay(dayjs());
                    setBpEvent(null);
                    setSelected(null);
                  }}
                  disabled={selectedDay.isToday()}
                >
                  {t('common.today')}
                </Button>
              </div>
              <Suspense fallback={<LazyLoader embedded size='xxl' forceHeight='calc(100vh - 780px)' />}>
                <ClassbookEvents
                  day={selectedDay}
                  className={styles['events-list']}
                  onEventClick={handleEventClick}
                  selectedUuid={selected ?? ''}
                />
              </Suspense>
            </BpCard>
          </GridColumn>
          <GridColumn width={7}>
            <Suspense fallback={<LazyLoader embedded size='xxl' transparent forceHeight='40vh' />}>
              <ClassbookEvent key={bpEvent?.uuid} eventUuid={bpEvent?.uuid ?? null} />
            </Suspense>
          </GridColumn>
        </GridRow>
      </Grid>
    </div>
  );
};
