import styles from './AssignmentPreview.module.scss';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmissionStatus, useBpSubmissionsQuery } from '../../client/bp-graphql-client-defs';
import { StatusChip } from '../StatusChip/StatusChip';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { Assignment } from '@bp/bp-graphql-types';
import classNames from 'classnames';
import { BpLink } from '../BpLink/BpLink';
import { useAuthClaims } from 'hooks/useAuthClaims';
import { useDays } from '../../hooks/useDays';

interface AssignmentWithStatus extends Assignment {
  status?: SubmissionStatus;
}

type AssignmentPreviewStudentProps = {
  assignment: AssignmentWithStatus;
  onNavigate: (uuid: string) => void;
};

export const AssignmentPreviewStudent: FC<AssignmentPreviewStudentProps> = ({ assignment, onNavigate }) => {
  const { t } = useTranslation();
  const { daysLeft } = useDays();
  const daysDifference = daysLeft(assignment.dueDate, true);

  const context = useMemoizedCacheTag('ASSIGNMENT');
  const { pimAuthClaims } = useAuthClaims();

  const [{ data }] = useBpSubmissionsQuery({
    variables: {
      where: {
        owner: {
          uuid: pimAuthClaims.getProfile().uuid ?? '',
        },
        assignmentConnection_SINGLE: {
          node: {
            uuid: assignment.uuid ?? '',
          },
        },
      },
    },
    context,
  });

  return (
    <div className={classNames(styles['assignment-preview'], styles['assignment-preview-student'])}>
      <div className={styles.top}>
        <div className={styles.text}>
          <div className={styles['due-days']}>
            {daysDifference < 0
              ? t('assignments.overdue')
              : t('dates.daysToGo', {
                  count: daysDifference,
                })}
          </div>
          <BpLink value={assignment?.title} onNavigate={() => onNavigate(assignment.uuid)} />
        </div>
      </div>
      <StatusChip status={data?.submissions[0]?.status ?? SubmissionStatus.Todo} />
    </div>
  );
};
