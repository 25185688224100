import { Button, Dropdown, EmptyState, MeetingActiveIcon, MeetingDefaultIcon, NoTasksIcon } from '@bp/ui-components';
import styles from '../_Flyouts.module.scss';
import { BpEventType } from '../../../EventsList/EventsList';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { useRunningPlannedMeetings } from '../../../../hooks/useCalendarEvents';
import dayjs from 'dayjs';
import { EventItem } from '../../../EventItem/EventItem';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { showErrorToast } from '../../../../utils/showErrorToast';
import { backendApi } from '../../../../utils/backendApi';
import { GroupRoles } from '@bp/pim-auth-constants';
import { useRefreshOnEvent } from '../../../../hooks/matrix/useRefreshOnEvent';

const MeetingsFlyout = () => {
  const { t } = useTranslation();
  useRefreshOnEvent();

  const { pimAuthClaims } = useAuthClaims();

  const { plannedMeetings, runningMeetings, refetchDashboardEvents } = useRunningPlannedMeetings(
    pimAuthClaims.getProfile().uuid,
  );

  const startMeeting = async (event: BpEventType) => {
    const onlineLocation = event.virtualLocations[0];
    if (onlineLocation) {
      const resp = await backendApi.startConference(onlineLocation.token);
      if (resp.ok) {
        refetchDashboardEvents();
      }
    } else {
      showErrorToast(t('meetings.noVirtualLocation'));
    }
  };
  const startAndJoinMeeting = async (event: BpEventType) => {
    const onlineLocation = event.virtualLocations[0];
    if (onlineLocation) {
      const joinUrl = await backendApi.joinConference(onlineLocation.token, {
        displayName: pimAuthClaims.getProfile().displayName ?? '',
        email: pimAuthClaims.getProfile().email ?? '',
        profileUuid: pimAuthClaims.getProfile().uuid ?? '',
        moderator:
          pimAuthClaims
            .getRoles()
            .find(
              (r) =>
                r.target.uuid === event.originUuid &&
                (r.name.includes(GroupRoles.Admin) || r.name.includes(GroupRoles.Editor)),
            ) !== undefined,
      });

      const url = await joinUrl.text();
      if (url.includes('https://')) {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.click();
      } else {
        showErrorToast(t('meetings.noVirtualLocation'));
      }
    } else {
      showErrorToast(t('meetings.noVirtualLocation'));
    }
  };

  const endMeeting = async (event: BpEventType) => {
    const onlineLocation = event.virtualLocations[0];
    if (onlineLocation) {
      const resp = await backendApi.endConference(onlineLocation.token);
      if (resp.ok) {
        refetchDashboardEvents();
      }
    } else {
      showErrorToast(t('meetings.noVirtualLocation'));
    }
  };

  const canStartMeeting = (event: BpEventType): boolean => {
    return event.owner;
  };
  const meetingStartable = (m: BpEventType): boolean => {
    return dayjs(m.start).isBefore(dayjs().add(1, 'hour'));
  };

  return (
    <Dropdown
      noPadding
      className={styles['flyout']}
      trigger={
        <Button
          hierarchy='tertiary'
          icon={runningMeetings.length > 0 ? <MeetingActiveIcon /> : <MeetingDefaultIcon />}
        />
      }
    >
      <div className={classNames(styles['flyout-body'], styles.meetings, 'has-scrollbar')}>
        {runningMeetings.length === 0 && plannedMeetings.length === 0 ? (
          <EmptyState
            forcedHeight='500px'
            maxWidth='200px'
            title={t('meetings.noMeeting')}
            icon={<NoTasksIcon className='svg-icon' />}
          />
        ) : (
          <>
            <div className={styles.running}>
              <div className={styles.header}>{t('meetings.title')}</div>
              {runningMeetings?.length === 0 ? (
                <EmptyState size='small' title={t('meetings.noRunningMeetings')} hideIcon padding='l' />
              ) : (
                runningMeetings.map((m) => (
                  <div className={styles['flyout-item']} key={m.uuid}>
                    <EventItem event={m} onClick={() => {}} isEmbedded isCompact={true} />
                    <div className={styles.actions}>
                      {canStartMeeting(m) && (
                        <Button hierarchy='secondary' onClick={() => endMeeting(m)}>
                          {t('meetings.end')}
                        </Button>
                      )}
                      <Button type='submit' hierarchy='primary' onClick={() => startAndJoinMeeting(m)}>
                        {t('meetings.join')}
                      </Button>
                    </div>
                  </div>
                ))
              )}
            </div>

            <div className={styles.planned}>
              <div className={styles.header}>{t('meetings.plannedMeetings')}</div>
              {plannedMeetings?.length === 0 ? (
                <EmptyState size='small' title={t('meetings.noPlannedMeetings')} hideIcon padding='l' />
              ) : (
                <>
                  {plannedMeetings?.map((m) => {
                    return (
                      <div className={styles['flyout-item']} key={m.uuid}>
                        <EventItem className={styles.event} event={m} isCompact={true} onClick={() => {}} isEmbedded />
                        {meetingStartable(m) && (
                          <div className={styles.actions}>
                            <Button
                              type='submit'
                              hierarchy='secondary'
                              className='mb-2'
                              onClick={async () => await startAndJoinMeeting(m)}
                            >
                              {t('meetings.startAndJoin')}
                            </Button>
                            <Button hierarchy='primary' onClick={() => startMeeting(m)}>
                              {t('meetings.start')}
                            </Button>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </Dropdown>
  );
};

export { MeetingsFlyout };
