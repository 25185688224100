import { TeachingUnitContextProvider } from '../../context/TeachingUnitContext';
import { LazyLoader } from '@bp/ui-components';
import { BpPage } from '../../components/BpPage/BpPage';
import { NavigationTabs, NavigationTabsType } from '../../components/NavigationTabs/NavigationTabs';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { FC, Suspense, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatches, useParams } from 'react-router-dom';
import { OrganizationConfigContext } from '../../context/OrganizationConfigContext';
import { useGroupAsCourseQuery } from '../../client/bp-graphql-client-defs';
import { GroupType } from '@bp/bp-graphql-types';

export const CoursePage: FC = () => {
  const { t } = useTranslation();
  const { courseUuid } = useParams();
  const perms = usePermissionChecker();

  const { cloudLicense, cloudLink } = useContext(OrganizationConfigContext);

  // hack hack hack for overview
  const matches = useMatches();
  const last = [...matches].pop();

  const context = useMemoizedCacheTag('COURSE');

  const [{ data }] = useGroupAsCourseQuery({
    context,
    variables: { where: { uuid: courseUuid ?? '', type: GroupType.Course } },
  });

  const currentCourse = data?.groups[0];

  const teacherTabs: NavigationTabsType[] = [
    {
      title: t('overview.title'),
      path: `/courses/${courseUuid}`,
      active: last?.id === 'course-overview',
    },
    {
      title: t('lessons.title'),
      path: `/courses/${courseUuid}/lessons`,
    },

    {
      title: t('assignments.title'),
      path: `/courses/${courseUuid}/assignments`,
    },
    {
      title: t('workmaterials.title'),
      path: `/courses/${courseUuid}/workmaterials`,
    },
    {
      title: t('appointments.title'),
      path: `/courses/${courseUuid}/appointments`,
    },
    {
      title: t('persons.title'),
      path: `/courses/${courseUuid}/persons`,
    },
    ...(perms?.isOmniAdmin()
      ? [
          {
            title: 'Admin',
            path: `/courses/${courseUuid}/admin`,
          },
        ]
      : []),
    {
      title: 'Cloud', // TODO: external-link Icon
      path: cloudLink,
      target: '_blank',
      disabled: !cloudLicense || cloudLink === '' || !currentCourse?.externalServices?.nextcloud?.enabled,
    },
  ];

  const studentTabs: NavigationTabsType[] = [
    {
      title: t('overview.title'),
      path: `/courses/${courseUuid}`,
      active: last?.id === 'course-overview',
    },

    {
      title: t('assignments.title'),
      path: `/courses/${courseUuid}/assignments`,
    },
    {
      title: t('workmaterials.title'),
      path: `/courses/${courseUuid}/workmaterials`,
    },
    {
      title: t('appointments.title'),
      path: `/courses/${courseUuid}/appointments`,
    },
    {
      title: t('persons.title'),
      path: `/courses/${courseUuid}/persons`,
    },
    {
      title: 'Gruppenarbeit',
      path: `/courses/${courseUuid}/gruppenarbeit`,
      disabled: true,
    },
    {
      title: 'Cloud',
      path: `/courses/${courseUuid}/cloud`,
      disabled: true,
    },
  ];

  return (
    <TeachingUnitContextProvider>
      <BpPage title={currentCourse?.name ?? t('courses.title')}>
        {perms?.canCreateCourse() ? (
          <NavigationTabs className='mb-6' tabs={teacherTabs} />
        ) : (
          <NavigationTabs className='mb-6' tabs={studentTabs} />
        )}
        <Suspense fallback={<LazyLoader embedded transparent forceHeight='45vh' />}>{<Outlet />}</Suspense>
      </BpPage>
    </TeachingUnitContextProvider>
  );
};
