import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AlertDialog,
  Button,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  EmptyStateSettings,
  ImportIcon,
  Modal,
  Table,
  useDefaultSorting,
} from '@bp/ui-components';
import { FileEntriesType, FileEntryType } from '../AssignmentForm/AssignmentForm';
import { useCopyToClipboard } from '@uidotdev/usehooks';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useBpDeleteFileEntryMutation, useBpFileEntriesQuery } from '../../client/bp-graphql-client-defs';
import { mimeTypes } from '../../utils/mime-types-map';
import { formatBytes } from '../../utils/formatBytes';
import { getFileUrl } from '../../utils/getFileUrl';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { FileRenameForm } from '../FileRenameForm/FileRenameForm';
import { handleFileEntryDownload } from '../../utils/download';
import { BpLink } from '../../components/BpLink/BpLink';

type FileTableProps = {
  mode: 'show' | 'edit';
  files: FileEntriesType;
  className?: string | undefined;
  onDeleted?: (uuid: string) => void;
  onRenamed?: (uuid: string, newName: string) => void;
  breakpoint?: string;
  padding?: string;
  emptyStateSettings?: EmptyStateSettings;
};

export const FileTable: FC<FileTableProps> = ({
  mode,
  className,
  files,
  onDeleted,
  onRenamed,
  breakpoint,
  padding = 'var(--spacing-6)',
  emptyStateSettings,
}) => {
  const { t } = useTranslation();
  const [, copyToClipboard] = useCopyToClipboard();
  const { pimAuthClaims } = useAuthClaims();

  const context = useMemoizedCacheTag('FILE_ENTRY');

  const [{ data: fileEntriesData }] = useBpFileEntriesQuery({
    variables: {
      where: { organization: { uuid: pimAuthClaims.getOrganizationUuid() }, uuid_IN: files.map((f) => f.uuid) },
    },
    context,
  });
  const [, deleteFile] = useBpDeleteFileEntryMutation();
  const onFileDelete = async (uuid: string) => {
    await deleteFile({ where: { uuid: uuid } }, context);
    onDeleted && onDeleted(uuid);
  };

  const filesWithMimeType: FileEntryType[] =
    fileEntriesData?.fileEntries.map((file) => {
      return {
        ...file,
        mediaType: mimeTypes[file.mediaType] ? mimeTypes[file.mediaType] : file.mediaType,
        formatSize: formatBytes(file.size),
      };
    }) ?? [];

  /* RenameModal */
  const [isOpen, setIsOpen] = React.useState(false);
  const [file, setFile] = React.useState<FileEntryType | null>(null);

  const handleModalClose = () => {
    setFile(null);
    setIsOpen(false);
  };

  function openModal(file: FileEntryType) {
    setFile(file);
    setIsOpen(true);
  }

  const { sorting, onSortingChange } = useDefaultSorting([{ id: 'filename', desc: false }]);

  const fileDropdownEditor = (file: FileEntryType): DropdownMenuItem[] => [
    {
      onClick: () => {
        openModal(file);
      },
      label: t('common.rename'),
    },
    {
      onClick: async () => {
        const currentFile = fileEntriesData?.fileEntries.find((fe) => fe.uuid === file.uuid);
        if (currentFile && currentFile.publicDownloadToken) {
          await copyToClipboard(getFileUrl(currentFile.uuid, currentFile.publicDownloadToken));
          showSuccessToast(t('common.copySuccess'));
        }
      },
      type: 'default',
      label: t('common.copyType', { type: 'Link' }),
    },
    {
      type: 'ruler',
    },
    {
      type: 'error',
      label: t('common.delete'),
      onClick: () => {},
      node: (
        <AlertDialog
          trigger={t('common.delete')}
          confirmText={t('common.delete')}
          cancelText={t('common.cancel')}
          title={t('common.delete')}
          onConfirm={async () => {
            onFileDelete(file.uuid);
          }}
        />
      ),
    },
  ];

  return (
    <div className={className}>
      <Table<FileEntryType>
        maxHeight={210}
        canScroll
        customPadding={padding}
        breakpoint={breakpoint}
        data={filesWithMimeType}
        columns={[
          {
            header: t('common.filename'),
            accessorKey: 'filename',
            id: 'filename',
            size: 400,
            canExpand: true,
            cell: ({ row }) => {
              return (
                <BpLink
                  value={row.original.filename}
                  onNavigate={() => {
                    handleFileEntryDownload(row.original.uuid);
                  }}
                />
              );
            },
          },
          {
            header: t('common.mediaType'),
            accessorKey: 'mediaType',
            id: 'mediaType',
            size: 60,
          },
          {
            header: t('common.size'),
            accessorKey: 'formatSize',
            id: 'formatSize',
            size: 100,
          },
        ]}
        hideHeader={filesWithMimeType.length === 0}
        emptyStateSettings={
          emptyStateSettings ?? {
            title: '',
            size: 'small',
            subtitle: t('files.noFiles'),
            padding: 'l',
          }
        }
        showSort
        sorting={sorting}
        onSortingChange={onSortingChange}
        lastColWidth='var(--spacing-6)'
        lastCol={(row) => {
          const currentFile = fileEntriesData?.fileEntries.find((fe) => fe.uuid === row.original.uuid);

          return (
            <div>
              {mode === 'show' && !!currentFile && (
                <Button
                  hierarchy='ghost'
                  icon={
                    <ImportIcon
                      className={'svg-icon'}
                      onClick={async () => {
                        if (currentFile && currentFile.downloadToken) {
                          handleFileEntryDownload(currentFile.uuid);
                        }
                      }}
                    />
                  }
                />
              )}
              {mode === 'edit' && !!onFileDelete && (
                <Dropdown
                  usePortal={false}
                  noPadding
                  trigger={<Button hierarchy='ghost' icon={<DotsHorizontalIcon />} />}
                >
                  <DropdownMenu data={fileDropdownEditor(row.original)} />
                </Dropdown>
              )}
            </div>
          );
        }}
      />
      {isOpen && file && (
        <Modal isOpen={isOpen} onRequestClose={handleModalClose} title={t('common.rename')} width='s'>
          <FileRenameForm
            file={file}
            onClose={handleModalClose}
            onRenamed={(uuid, newName) => {
              onRenamed && onRenamed(uuid, newName);
            }}
          />
        </Modal>
      )}
    </div>
  );
};
