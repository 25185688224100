import styles from './MeetingsLinks.module.scss';
import classNames from 'classnames';
import { MeetingActiveIcon, MeetingDefaultIcon, MessagesDefaultIcon } from '@bp/ui-components';
import { useMatrixAvailable } from '../../hooks/matrix/useMatrixAvailable';
import { InstantMeetingModal } from '../Modals/InstantMeetingModal';
import { useState } from 'react';

type MeetingsLinksProps = {
  onLessonText: string;
  onMessageText: string;
  hasActiveMeetingText: string;
  onLessonClick: (() => void) | null;
  onMessageClick: (() => void) | null;
  hasActiveMeeting: boolean;
  hasBlockingEvents?: boolean;
  hasBlockingEventsText?: string;
};

export const MeetingsLinks = ({
  onLessonText,
  onMessageText,
  onLessonClick,
  onMessageClick,
  hasActiveMeeting,
  hasActiveMeetingText,
  hasBlockingEvents = false,
  hasBlockingEventsText = 'blocked',
}: MeetingsLinksProps) => {
  const matrixAvailable = useMatrixAvailable();
  const [instantMeetingModalOpen, setInstantMeetingModalOpen] = useState<boolean>(false);

  return (
    <>
      <div className={styles['meetings-links']}>
        {onLessonClick && !hasActiveMeeting && !hasBlockingEvents && (
          <div
            className={styles.link}
            onClick={() => {
              setInstantMeetingModalOpen(!instantMeetingModalOpen);
            }}
          >
            <MeetingDefaultIcon className={classNames('svg-icon', styles.icon)} />
            <div className={styles.text}>{onLessonText}</div>
          </div>
        )}

        {hasActiveMeeting && (
          <div className={`${styles.link} ${styles.disabled}`}>
            <MeetingActiveIcon className={classNames('svg-icon', styles.icon)} />
            <div className={styles.text}>{hasActiveMeetingText}</div>
          </div>
        )}

        {hasBlockingEvents && (
          <div className={`${styles.link} ${styles.disabled}`}>
            <MeetingDefaultIcon className={classNames('svg-icon', styles.icon)} />
            <div className={`${styles.text} ${styles.center}`}>{hasBlockingEventsText}</div>
          </div>
        )}

        {matrixAvailable && onMessageClick && (
          <div className={styles.link} onClick={onMessageClick}>
            <MessagesDefaultIcon className={classNames('svg-icon', styles.icon)} />
            <div className={styles.text}>{onMessageText}</div>
          </div>
        )}
      </div>
      <InstantMeetingModal
        setInstantMeetingModalOpen={setInstantMeetingModalOpen}
        showModal={instantMeetingModalOpen}
      />
    </>
  );
};
