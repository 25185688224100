import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Logo, Modal } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import styles from './AboutModal.module.scss';
import { useMatrixClient } from '../../../hooks/matrix/useMatrixClient';
import { useWindowDimensions } from 'utils/dimensions';

export type AboutModalProps = {
  state: boolean;
  setState: Dispatch<SetStateAction<boolean>>;
  isAdmin: boolean;
};

type PimHealth = {
  name?: string;
  version?: string;
  status: string;
  ok: boolean;
  neo4j?: {
    name?: string;
    ok: boolean;
    status?: string;
    message?: string;
  };
};

type ApolloHealth = {
  status: string;
  ok: boolean;
  version?: string;
};

export const AboutModal = ({ state, setState, isAdmin }: AboutModalProps) => {
  const { t } = useTranslation();
  const matrix = useMatrixClient();

  const { isPhone, isTablet } = useWindowDimensions();
  const isSmall = isPhone || isTablet;

  const [bpGraphHealth, setBpGraphHealth] = useState<ApolloHealth | null>({
    status: 'ERROR',
    ok: false,
  });

  const [pimHealth, setPimHealth] = useState<PimHealth | null>({
    status: 'ERROR',
    ok: false,
  });

  useEffect(() => {
    fetch(import.meta.env.VITE_APP_BACKEND_URI + '/_health')
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }
        throw resp;
      })
      .then((data) => setBpGraphHealth(data))
      .catch(() => {
        setBpGraphHealth({ status: 'ERROR', ok: false });
      });

    fetch(import.meta.env.VITE_APP_AUTH_SERVER_URI + '/_health')
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }
        throw resp;
      })
      .then((data) => setPimHealth(data))
      .catch(() => {
        setPimHealth({ status: 'ERROR', ok: false });
      });
  }, []);

  let buildTime: string | undefined = import.meta.env.VITE_APP_BUILD_TIME;
  if (buildTime === undefined) {
    buildTime = 'hmr/live';
  }

  const pimOnline = pimHealth?.status === 'green';
  const graphOnline = bpGraphHealth?.status;
  const matrixOnline = matrix?.clientRunning;

  return (
    <Modal
      className={styles['about-modal']}
      title={t('app.title.full')}
      subtitle={
        !isSmall
          ? (APP_VERSION ?? 'dev') + '/' + import.meta.env.VITE_APP_ENVIRONMENT + ' (' + buildTime + ')'
          : undefined
      }
      shouldCloseOnOverlayClick
      isOpen={state}
      onRequestClose={() => setState(false)}
    >
      {isSmall && (
        <div className={styles['version-hint']}>
          {(APP_VERSION ?? 'dev') + '/' + import.meta.env.VITE_APP_ENVIRONMENT + ' (' + buildTime + ')'}
        </div>
      )}
      <div className={styles.help}>
        <div className={styles.info}>
          {isAdmin ? (
            <>
              <div className={styles.contact}>
                <div>{t('about.info')}</div>
                <div>+49 (0) 711 – 99 58 80 88</div>
                <div>support@bildungsplattform.org</div>
              </div>
              <div className={styles.documentation}>
                <div>{t('about.documentation')}</div>
                <a target='_blank' href='https://r.tks.eu/bp-doku' rel='noreferrer'>
                  {t('about.documentationLinkText')}
                </a>
              </div>
            </>
          ) : (
            <div>{t('about.askAdmin')}</div>
          )}
        </div>
        <img src={'/digitalpakt_schule_logoleiste.png'} alt={t('about.logosAltText')} />
      </div>
      <div className={styles.footer}>
        <div className={styles.address}>
          <Logo className={styles.logo} type={'tk'} collapsed={false} />
          <div>
            <div>TK-Schulsoftware GmbH & Co. KG</div>
            <div>Rosenwiesstr. 17</div>
            <div>70567 Stuttgart</div>
          </div>
        </div>
        <div className={styles.products}>
          <div className={styles.product}>TK-Login (Version: {pimHealth?.version ?? 'unknown'})</div>
          <div className={pimOnline ? styles.online : undefined}>{pimOnline ? 'Online' : 'Offline'}</div>

          <div className={styles.product}>Server (Version: {bpGraphHealth?.version ?? 'unknown'})</div>
          <div className={graphOnline ? styles.online : undefined}>{graphOnline ? 'Online' : 'Offline'}</div>

          <div className={styles.product}>Matrix</div>
          <div className={matrixOnline ? styles.online : undefined}>{matrixOnline ? 'Online' : 'Offline'}</div>
        </div>
      </div>
    </Modal>
  );
};
